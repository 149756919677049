/* eslint-disable import/no-extraneous-dependencies */
import { useEffect, useRef, useState } from 'react';

import { Button } from '@/components/Button';
import EditorPlateComponent from '@/components/Editor/FullEditor/EditorPlateComponent';
import { Label } from '@/components/Label';
import { ScrollArea, ScrollBar } from '@/components/ScrollArea';
import { UserAvatar } from '@/components/UserAvatar';
import { useErrorHandler, useSuccessHandler } from '@/hooks/useToasterHandler';
import { MemoizedRenderDocument } from '@/renders/RenderDocument';
import { MemoizedRenderMember } from '@/renders/RenderMember';
import {
  DocumentVersionFragment,
  useGetDocumentVersionsQuery,
  useRestoreDocumentVersionMutation,
} from '@/types/tanstack-query/generated';
import CustomDateTime from '@/utils/format-datetime';

import PlateUtils from '../../../utils/plate-utils';

const DocumentContentHistoryComponent = (props: {
  document_id: string;
  onCancel: () => void;
}) => {
  const { showSuccessToast } = useSuccessHandler();
  const { showErrorToast } = useErrorHandler();
  const editorContentRef = useRef<any>(null);
  const [selected, setSelected] = useState<DocumentVersionFragment>();
  const restore = useRestoreDocumentVersionMutation({
    onMutate: () => {},
    onError: (res) => {
      showErrorToast({ error: res });
    },
    onSuccess: () => {
      showSuccessToast({
        title: 'Version has been restored',
        description: `Restored to ${CustomDateTime.convertStringToCommon(
          selected?.created_at ?? '',
        )}`,
      });
      props.onCancel();
    },
    onSettled: () => {},
  });

  const getDocumentContentHistory = useGetDocumentVersionsQuery(
    {
      getInput: {
        document_id: props.document_id,
      },
    },
    { enabled: !!props.document_id },
  );

  useEffect(() => {
    if (
      !getDocumentContentHistory.isLoading &&
      getDocumentContentHistory.data?.documentVersions &&
      getDocumentContentHistory.data?.documentVersions.length > 0
    ) {
      setSelected(getDocumentContentHistory.data?.documentVersions[0]);
    }
  }, [getDocumentContentHistory.isSuccess]);

  useEffect(() => {
    // Editor Content
    if (!editorContentRef.current) return;
    if (selected?.content) {
      editorContentRef.current.children = PlateUtils.getTElementFromContent(
        selected.content,
      );
      editorContentRef.current.onChange();
    } else {
      editorContentRef.current.children = [];
      editorContentRef.current.onChange();
    }
  }, [selected]);
  return (
    <div className="flex h-full flex-col gap-2 overflow-hidden">
      <div className="w-full">
        <MemoizedRenderDocument
          id={props.document_id}
          renderItem={(document) => (
            <Label className="text-2xl">{document?.title}</Label>
          )}
        />
      </div>
      <div className="flex w-full grow flex-col gap-2 overflow-hidden lg:flex-row">
        <div className="flex w-full flex-none lg:hidden">
          <div className="w-full overflow-hidden rounded bg-secondary">
            <select
              value={selected?.id}
              className="w-full bg-secondary p-2"
              onChange={(value) =>
                setSelected(
                  getDocumentContentHistory.data?.documentVersions.find(
                    (item) => item.id === value.target.value,
                  ),
                )
              }
            >
              {getDocumentContentHistory.data?.documentVersions.map((item) => (
                <option key={item.id} value={item.id}>
                  <Label className="grow text-left text-xs">
                    {CustomDateTime.convertStringToDateTime(
                      item.created_at,
                      'PP',
                    )}{' '}
                    <span className="opacity-50">
                      {CustomDateTime.convertStringToDateTime(
                        item.created_at,
                        'p',
                      )}
                    </span>{' '}
                    {selected?.id === item.id && (
                      <span className="opacity-50">(Current version)</span>
                    )}
                  </Label>
                </option>
              ))}
            </select>
          </div>
        </div>
        <div className="grow rounded border border-solid p-2">
          <ScrollArea type="auto" className="h-full">
            <EditorPlateComponent
              id={`${selected?.id}-content`}
              type="document"
              workspace_id={selected?.workspace_id ?? ''}
              placeholder="Description ..."
              readOnly={true}
              className="min-h-[200px]"
              content={selected?.content ?? ''}
            />
            <ScrollBar orientation="vertical" />
          </ScrollArea>
        </div>
        <div className="hidden h-full w-[260px] flex-none lg:block">
          <ScrollArea type="auto" className="h-full">
            <div className="flex flex-col gap-4">
              {getDocumentContentHistory.data?.documentVersions.map((item) => (
                <Button
                  onClick={() => setSelected(item)}
                  variant={'secondary'}
                  className="h-[60px] p-2"
                >
                  <div className="relative flex w-full flex-col gap-2">
                    <div className="items-centers flex w-full flex-row justify-start">
                      <Label className="grow text-left text-xs">
                        {CustomDateTime.convertStringToDateTime(
                          item.created_at,
                          'PP',
                        )}{' '}
                        <span className="opacity-50">
                          {CustomDateTime.convertStringToDateTime(
                            item.created_at,
                            'p',
                          )}
                        </span>
                      </Label>
                      {selected?.id === item.id && (
                        <Button
                          className="absolute -top-[5px] right-0 text-xs"
                          size={'sm'}
                          variant={'outline'}
                        >
                          Current
                        </Button>
                      )}
                    </div>
                    <MemoizedRenderMember
                      id={item.created_by}
                      renderItem={(member) => (
                        <div className="flex flex-row gap-2">
                          <UserAvatar size={'sm'} fallback={member?.username} />
                          <div className="line-clamp-1">
                            <Label className="line-clamp-1 flex-none text-xs">
                              {member?.email}
                            </Label>
                          </div>
                        </div>
                      )}
                    />
                  </div>
                </Button>
              ))}
            </div>
            <ScrollBar orientation="vertical" />
          </ScrollArea>
        </div>
      </div>
      <div className="flex w-full flex-none flex-row items-center justify-end gap-4 pt-[10px]">
        <Button
          onClick={() => props.onCancel && props.onCancel()}
          variant={'secondary'}
        >
          Cancel
        </Button>
        <Button
          onClick={() => {
            if (!selected?.id) return;
            restore.mutate({ id: selected?.id });
          }}
          variant={'default'}
        >
          Restore
        </Button>
      </div>
    </div>
  );
};
export default DocumentContentHistoryComponent;
