import { useContext } from 'react';

import { startOfDay, addDays } from 'date-fns';

import { Badge } from '@/components/Badge';
import { Button } from '@/components/Button';
import { Card, CardContent } from '@/components/Card';
import { Label } from '@/components/Label';
import { ScrollArea, ScrollBar } from '@/components/ScrollArea';
import { Separator } from '@/components/Separator';
import AppointmentServicesContext from '@/providers/appointment/AppointmentServicesContext';
import WorkspaceContext from '@/providers/workspace/WorkspaceContext';
import { AppointmentFragment } from '@/types/tanstack-query/generated';

import AppointmentTableList from '../components/AppointmentTableList/AppointmentTableList';
import MyAppointmentHeader from '../components/MyAppointmentHeader/MyAppointmentHeader';

const DateSection = (props: {
  title: string;
  appointments: AppointmentFragment[];
}) => {
  return (
    <>
      <div className="flex h-[38px] flex-row items-center gap-4 bg-secondary bg-opacity-20 px-[10px]">
        <div className="flex grow flex-row items-center gap-2">
          <div className="flex w-full flex-none gap-4">
            <Label className="font-bold">{props.title}</Label>
            <Badge>{props.appointments.length}</Badge>
          </div>
        </div>
      </div>
      <Separator className="w-full" orientation="horizontal" />
      <AppointmentTableList data={props.appointments} />
    </>
  );
};

const EmptyAppointment = () => {
  const { showCreateAppointmentPopup } = useContext(AppointmentServicesContext);
  return (
    <div className="flex grow flex-col items-center justify-center">
      <Card className="max-w-[500px] bg-secondary">
        <CardContent className="p-6">
          <div className="flex flex-col gap-2">
            <Label className="text-xl font-semibold">Appointments</Label>
            <Label className="opacity-50">
              Create an appointment when you want to schedule a meeting or
              appointment.
            </Label>
            <div className="pt-[20px]">
              <Button
                size={'sm'}
                type="button"
                onClick={() => {
                  showCreateAppointmentPopup({
                    isOpen: true,
                    initDate: new Date(),
                  });
                }}
                variant={'default'}
              >
                Create a new appointment
              </Button>
            </div>
          </div>
        </CardContent>
      </Card>
    </div>
  );
};

const AppointmentListViewPage = () => {
  const { selected: selectedWorkspace, appointments } =
    useContext(WorkspaceContext);

  const today = startOfDay(new Date());
  const tomorrow = startOfDay(addDays(new Date(), 1));

  const getAppointmentsByToday = appointments.filter((x) => {
    return startOfDay(new Date(x.time_at)).getTime() === today.getTime();
  });

  const getAppointmentsByTomorrow = appointments.filter((x) => {
    return startOfDay(new Date(x.time_at)).getTime() === tomorrow.getTime();
  });

  const getAppointmentsByUpcoming = appointments.filter((x) => {
    return startOfDay(new Date(x.time_at)).getTime() > tomorrow.getTime();
  });

  return (
    <div className="flex h-full w-full flex-col">
      <MyAppointmentHeader active={'list'} />
      {appointments.length === 0 && <EmptyAppointment />}
      {appointments.length > 0 && (
        <div className="flex h-full w-full flex-col">
          {selectedWorkspace && (
            <ScrollArea type="auto" style={{ maxHeight: '100%' }}>
              {getAppointmentsByToday.length > 0 && (
                <div>
                  <DateSection
                    title="Today"
                    appointments={getAppointmentsByToday}
                  ></DateSection>
                  <ScrollBar orientation="vertical" />
                </div>
              )}
              {getAppointmentsByTomorrow.length > 0 && (
                <div>
                  <DateSection
                    title="Tomorrow"
                    appointments={getAppointmentsByTomorrow}
                  ></DateSection>
                  <ScrollBar orientation="vertical" />
                </div>
              )}
              {getAppointmentsByUpcoming.length > 0 && (
                <div>
                  <DateSection
                    title="Upcoming Schedule"
                    appointments={getAppointmentsByUpcoming}
                  ></DateSection>
                  <ScrollBar orientation="vertical" />
                </div>
              )}
            </ScrollArea>
          )}
        </div>
      )}
    </div>
  );
};

export default AppointmentListViewPage;
