import { clsx, type ClassValue } from 'clsx';
import { twMerge } from 'tailwind-merge';

export function cn(...inputs: ClassValue[]) {
  return twMerge(clsx(inputs));
}

export const defaultEditorValue = [
  { id: 1, type: 'p', children: [{ text: '' }] },
];
