import { useState } from 'react';

const useProvider = () => {
  const [isShowCreateAppointment, setIsShowCreateAppointment] =
    useState<boolean>(false);

  const showCreateAppointmentPopup = (value: boolean) => {
    setIsShowCreateAppointment(value);
  };
  return {
    isShowCreateAppointment,
    event: {
      showCreateAppointmentPopup,
    },
  };
};

export default useProvider;
