/* eslint-disable import/no-extraneous-dependencies */

import { useEffect, useState } from 'react';

import { Button } from '@/components/Button';
import { Card } from '@/components/Card';
import EditorPlateComponent from '@/components/Editor/FullEditor/EditorPlateComponent';
import { useErrorHandler } from '@/hooks/useToasterHandler';
import { useWarningHandler } from '@/hooks/useWarningHandler';
import { useCreateCommentMutation } from '@/types/tanstack-query/generated';
import { generateUuid } from '@/utils/generate';

const CreateCommentComponent = (props: {
  issue_id: string;
  workspace_id: string;
}) => {
  const { showWarningToast } = useWarningHandler();
  const { showErrorToast } = useErrorHandler();

  const [randomId, setRandomId] = useState<string>(generateUuid());

  const [description, setDescription] = useState<string>();
  const onResetForm = () => {
    setRandomId(generateUuid());
    setDescription(undefined);
  };

  const createComment = useCreateCommentMutation({
    onMutate: () => {},
    onError: (res) => {
      showErrorToast({ error: res });
    },
    onSuccess: () => {
      onResetForm();
    },
    onSettled: () => {},
  });

  const onSubmit = () => {
    if (!description) {
      showWarningToast({
        title: 'Comment is required',
        description: 'Please add a comment before submitting.',
      });
      return;
    }

    createComment.mutate({
      createInput: {
        content: description,
        issue_id: props.issue_id,
        workspace_id: props.workspace_id,
      },
    });
  };

  // Reset when user navigate to new issue
  useEffect(() => {
    onResetForm();
  }, [props.issue_id]);

  return (
    <Card className="bg-secondary p-4">
      <EditorPlateComponent
        id={`${randomId}-comment`}
        type="comment"
        workspace_id={props.workspace_id}
        placeholder="Leave comment ..."
        className="min-h-[50px] bg-secondary"
        onSave={(value) => setDescription(value)}
      />
      <div className="flex w-full justify-end gap-2 pt-2">
        <Button
          onClick={() => onSubmit()}
          type="button"
          disabled={createComment.isPending}
        >
          Comment
        </Button>
      </div>
    </Card>
  );
};

export default CreateCommentComponent;
