/* eslint-disable import/no-extraneous-dependencies */

import React, { useEffect, useState } from 'react';

import { cn } from '@udecode/cn';
import { Value } from '@udecode/plate';
import { Plate } from '@udecode/plate/react';
import { useFilePicker } from 'use-file-picker';

import { Button } from '@/components/Button';
import { Editor, EditorContainer } from '@/components/plate-ui/editor';
import { TooltipProvider } from '@/components/plate-ui/tooltip';
import { useDebounce } from '@/hooks/use-debounce';
import { AttachmentIcon } from '@/icons/core';
import PlateUtils from '@/utils/plate-utils';

import useFullEditor from './useFullEditor';

const EditorPlateComponent = React.memo(
  (props: {
    id: string;
    type: 'issue' | 'project' | 'document' | 'comment' | 'appointment';
    workspace_id: string;
    content?: string | undefined;
    placeholder?: string;
    readOnly?: boolean;
    disabled?: boolean;
    className?: string;
    onSave?: (content: string | undefined) => void;
    onDraft?: (value: boolean) => void;
  }) => {
    const mergeId = `${props.id};${props.type};${props.workspace_id}`;
    const [draftContent, setDraftContent] = useState<Value>();
    const debounceInput = useDebounce(draftContent, 500);

    const editor = useFullEditor(mergeId);

    useEffect(() => {
      if (debounceInput) {
        try {
          const getContent = PlateUtils.getContentFromTElement(debounceInput);
          if (getContent?.length === props.content?.length) return;
          props.onSave?.(getContent);
        } catch (error) {
          console.log(error);
        }
      }
    }, [debounceInput]);

    const { openFilePicker } = useFilePicker({
      accept: ['image/*'],
      multiple: true,
      onFilesSelected: ({ plainFiles: updatedFiles }) => {
        editor.tf.insert.media(updatedFiles);
      },
    });

    const onVerifyDraft = () => {
      const getContent = PlateUtils.getContentFromTElement(editor.children);
      if (props.content?.length !== getContent?.length) {
        props.onDraft?.(true);
      } else {
        props.onDraft?.(false);
      }
    };

    useEffect(() => {
      try {
        if (props.content) {
          editor.tf.setValue(PlateUtils.getTElementFromContent(props.content));
        } else {
          editor.tf.setValue([]);
        }
      } catch (error) {
        console.log(error);
      }
    }, [props.id]);

    useEffect(() => {
      onVerifyDraft();
    }, [props.content]);

    return (
      <>
        <div className="w-full">
          <TooltipProvider>
            <Plate
              editor={editor}
              onChange={() => {
                onVerifyDraft();
              }}
            >
              <EditorContainer>
                <Editor
                  onBlur={() => {
                    onVerifyDraft();
                    setDraftContent(editor.children);
                  }}
                  readOnly={props.readOnly}
                  disabled={props.disabled}
                  placeholder={props.placeholder}
                  style={{ padding: 0 }}
                  className={cn(props.className)}
                />
                {!props.readOnly && (
                  <div className="absolute bottom-[5px] right-0 flex">
                    <Button
                      type="button"
                      size={'sm'}
                      variant={'ghost'}
                      onClick={() => {
                        openFilePicker();
                      }}
                    >
                      <div className="flex flex-row items-center gap-2">
                        <AttachmentIcon width={16} height={16} />
                      </div>
                    </Button>
                  </div>
                )}
              </EditorContainer>
            </Plate>
          </TooltipProvider>
        </div>
      </>
    );
  },
);

export default EditorPlateComponent;
