import { useContext, useEffect, useState } from 'react';

import SubscriptionContext from '@/providers/subscriptions/SubscriptionContext';
import {
  CommentFragment,
  PushEventType,
} from '@/types/tanstack-query/generated';

const useComments = () => {
  const { pubsub } = useContext(SubscriptionContext);

  const [comments, setComments] = useState<CommentFragment[]>([]);

  const storeComments = async (value: CommentFragment[]) => {
    setComments(value);
  };

  const pushItem = async (props: { comment: CommentFragment }) => {
    const clone = Object.assign([], comments);
    clone.push(props.comment);
    setComments(clone);
  };

  const updateItem = async (props: { comment: CommentFragment }) => {
    const clone: CommentFragment[] = Object.assign([], comments);
    setComments(
      clone.map((item) => {
        if (item.id === props.comment.id) return { ...props.comment };
        return { ...item };
      }),
    );
  };

  const popItem = async (props: { id: string }) => {
    const clone: CommentFragment[] = Object.assign(
      [],
      comments.filter((item) => item.id !== props.id),
    );
    setComments(clone);
  };

  useEffect(() => {
    if (pubsub && pubsub.event) {
      switch (pubsub.event) {
        case PushEventType.CommentAdded:
          {
            const comment = pubsub.data as CommentFragment;
            pushItem({ comment: comment });
          }
          break;
        case PushEventType.CommentUpdated:
          {
            const comment = pubsub.data as CommentFragment;
            updateItem({ comment: comment });
          }
          break;
        case PushEventType.CommentDeleted:
          if (!pubsub.id) break;
          popItem({ id: pubsub.id });
          break;
        default:
          break;
      }
    }
  }, [pubsub]);

  return {
    comments,
    event: {
      storeComments,
    },
  };
};

export default useComments;
