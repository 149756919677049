import { useContext, useEffect, useState } from 'react';

import uniqBy from 'lodash/unionBy';
import uniq from 'lodash/uniq';
import { create } from 'zustand';

import useIssuesMultiFilters from '@/hooks/useIssuesMultiFilters';
import WorkspaceContext from '@/providers/workspace/WorkspaceContext';
import {
  WorkflowFragment,
  LabelFragment,
} from '@/types/tanstack-query/generated';
import CustomDateTime from '@/utils/format-datetime';

type IPageState = {
  dates: string[];
  setDates: (value: string[]) => void;
};

const createStore = () =>
  create<IPageState>((set) => ({
    dates: [],
    setDates: (value: string[]) => {
      return set({
        dates: value,
      });
    },
  }));

const useIssuesActivity = () => {
  const [useStore] = useState(createStore);

  const {
    issues: cores,
    workflows: workflowsCore,
    labels: labelsCore,
    members,
  } = useContext(WorkspaceContext);
  const [workflows, setWorkflows] = useState<WorkflowFragment[]>([]);
  const [labels, setLabels] = useState<LabelFragment[]>([]);
  const { dates, setDates } = useStore();
  const {
    issues,
    selectedStatuses,
    selectedConditionStatus,
    selectedPriorities,
    selectedConditionPriority,
    selectedLabels,
    selectedConditionLabel,
    selectedAssignees,
    selectedConditionAssignee,
    event: {
      setIssues,
      setSelectedStatuses,
      setSelectedConditionStatus,
      setSelectedPriorities,
      setSelectedConditionPriority,
      setSelectedLabels,
      setSelectedConditionLabel,
      setSelectedAssignees,
      setSelectedConditionAssignee,
    },
  } = useIssuesMultiFilters();

  useEffect(() => {
    const getIssues = cores.filter((item) => item.is_deleted === false);
    setIssues(getIssues);
  }, [cores]);

  useEffect(() => {
    const getDates = uniq(
      issues.map((item) =>
        CustomDateTime.convertStringToDateTime(item.updated_at, 'yyyy-MM-dd'),
      ),
    );
    setDates(getDates);
  }, [issues]);

  useEffect(() => {
    const getList = uniqBy(
      workflowsCore,
      (item) => `${item.name} ${item.type}`,
    );
    setWorkflows(getList);
  }, [workflowsCore]);

  useEffect(() => {
    const getList = uniqBy(labelsCore, (item) => `${item.name}`);
    setLabels(getList);
  }, [labelsCore]);

  return {
    issues,
    workflows,
    labels,
    members,
    dates,
    selectedStatuses,
    selectedConditionStatus,
    selectedPriorities,
    selectedConditionPriority,
    selectedLabels,
    selectedConditionLabel,
    selectedAssignees,
    selectedConditionAssignee,
    event: {
      setSelectedStatuses,
      setSelectedConditionStatus,
      setSelectedPriorities,
      setSelectedConditionPriority,
      setSelectedLabels,
      setSelectedConditionLabel,
      setSelectedAssignees,
      setSelectedConditionAssignee,
    },
  };
};

export default useIssuesActivity;
