import { useContext } from 'react';

import { PlusIcon } from 'lucide-react';
import { useNavigate } from 'react-router-dom';

import { Button } from '@/components/Button';
import { Label } from '@/components/Label';
import AppointmentServicesContext from '@/providers/appointment/AppointmentServicesContext';
import { TopNavigationBody } from '@/providers/main/components/TopNavigationBody';
import WorkspaceContext from '@/providers/workspace/WorkspaceContext';
import { IAppointmentMenu } from '@/types/tanstack-query/core/core';

const MyAppointmentHeader = (props: { active?: IAppointmentMenu }) => {
  const { selected } = useContext(WorkspaceContext);
  const { showCreateAppointmentPopup } = useContext(AppointmentServicesContext);
  const navigate = useNavigate();

  const menus = [
    { name: 'List view', value: 'list' },
    { name: 'Calendar view', value: 'calendar' },
  ];

  const onNavigate = (item: string) => {
    navigate(`/${selected?.url}/appointments/${item}`);
  };

  return (
    <TopNavigationBody>
      <div className="flex w-full flex-none flex-row items-center gap-2">
        <div onClick={() => navigate(`/${selected?.url}/appointments/list`)}>
          <Label className="font-semibold">My appointments</Label>
        </div>
        <div className="flex grow flex-row items-center gap-4">
          {menus.map((item) => (
            <Button
              key={item.value}
              type="button"
              variant={props.active === item.value ? 'default' : 'outline'}
              onClick={() => onNavigate(item.value)}
            >
              <Label>{item.name}</Label>
            </Button>
          ))}
        </div>
        <div className="flex-none">
          <Button
            type="button"
            variant={'ghost'}
            size={'sm'}
            onClick={() =>
              showCreateAppointmentPopup({
                isOpen: true,
                initDate: new Date(),
              })
            }
          >
            <PlusIcon width={20} height={20} />
          </Button>
        </div>
      </div>
    </TopNavigationBody>
  );
};
export default MyAppointmentHeader;
