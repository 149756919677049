import { useContext } from 'react';

import { yupResolver } from '@hookform/resolvers/yup';
import { useForm } from 'react-hook-form';
import * as yup from 'yup';

import { Button } from '@/components/Button';
import { Form, FormField } from '@/components/Form';
import { Input } from '@/components/Input';
import { Label } from '@/components/Label';
import { LabelError } from '@/components/LabelError';
import { useErrorHandler, useSuccessHandler } from '@/hooks/useToasterHandler';
import EmojiIcon, { EmojiIconList, IEmojiIcon } from '@/icons/emoji';
import WorkspaceContext from '@/providers/workspace/WorkspaceContext';
import { BaseColors } from '@/services/core/constant';
import { useCreateTeamMutation } from '@/types/tanstack-query/generated';

import HeaderSetting from '../../components/HeaderSetting';
import IconPicker from '../../components/IconPicker';
import ToggleSetting from '../../components/ToggleSetting';

export type ICreateTeam = {
  name: string;
  icon: IEmojiIcon;
  color: string;
  identifier: string;
};

export const schemaCreateTeam = yup.object().shape({
  name: yup.string().required(),
  icon: yup
    .mixed<IEmojiIcon>()
    .oneOf([...EmojiIconList])
    .required(),
  color: yup.string().required(),
  identifier: yup
    .string()
    .min(3, 'Must be exactly 3 characters')
    .max(3, 'Must be exactly 3 characters')
    .required(),
});

const SettingNewTeamPage = () => {
  const { showErrorToast } = useErrorHandler();
  const { showSuccessToast } = useSuccessHandler();

  const { selected } = useContext(WorkspaceContext);
  const form = useForm<ICreateTeam>({
    resolver: yupResolver<ICreateTeam>(schemaCreateTeam),
    defaultValues: {
      icon: EmojiIconList[0] ?? 'EmojiBabyChick',
      name: '',
      color: BaseColors[0] ?? '',
      identifier: '',
    },
  });
  const createTeam = useCreateTeamMutation({
    onMutate: () => {},
    onError: (res) => {
      showErrorToast({ error: res });
    },
    onSuccess: (res) => {
      form.reset();
      showSuccessToast({
        title: `${res.createTeam.name} has been created`,
      });
    },
    onSettled: () => {},
  });

  const onSubmit = (data: ICreateTeam) => {
    if (!selected?.id) return;
    createTeam.mutate({
      createInput: {
        icon: data.icon ?? '',
        name: data.name ?? '',
        color: data.color ?? '',
        identifier: data.identifier,
        workspace_id: selected?.id,
      },
    });
  };

  return (
    <div>
      <HeaderSetting
        title={'Create a new team'}
        subtitle={
          'Create a new team to manage separate, workflows and notifications'
        }
      ></HeaderSetting>
      <div>
        <Form {...form}>
          <form onSubmit={form.handleSubmit(onSubmit)}>
            <div className="flex w-full flex-col gap-8">
              <div className="flex w-full flex-col gap-2">
                <Label className="font-semibold">Team icon & name</Label>
                <div className="flex w-full flex-row items-center gap-4">
                  <IconPicker
                    icon={form.watch('icon')}
                    color={form.watch('color')}
                    onSelectedIcon={(data) => {
                      form.setValue('icon', data);
                    }}
                    onSelectedColor={(data) => {
                      form.setValue('color', data);
                    }}
                  >
                    <Button
                      type="button"
                      variant="outline"
                      size="icon"
                      className={`opacity-100`}
                    >
                      <EmojiIcon
                        tag={form.watch('icon')}
                        attrs={{
                          width: 28,
                          height: 28,
                          color: form.watch('color'),
                        }}
                      />
                    </Button>
                  </IconPicker>
                  <FormField
                    control={form.control}
                    name="name"
                    render={({ field }) => (
                      <Input
                        placeholder="e.g. Engineering"
                        className="w-full"
                        {...field}
                      ></Input>
                    )}
                  />
                </div>

                <LabelError>{form.formState.errors.name?.message}</LabelError>
              </div>
              <FormField
                control={form.control}
                name="identifier"
                render={({ field }) => (
                  <div className="flex w-full flex-col gap-2">
                    <Label className="font-semibold">Team identifier</Label>
                    <div className="flex w-full flex-row items-center gap-4">
                      <Input
                        placeholder="e.g. ENG"
                        className="w-[120px]"
                        {...field}
                      ></Input>
                      <div className="w-full grow">
                        <Label className="opacity-50">
                          This is used as the identifier (e.g. ENG-123) for all
                          issues of the team. Keep it short and simple.
                        </Label>
                      </div>
                    </div>

                    <LabelError>
                      {form.formState.errors.identifier?.message}
                    </LabelError>
                  </div>
                )}
              />

              <ToggleSetting />
              <div className="mt-[30px]">
                <Button
                  disabled={createTeam.isPending || !form.formState.isDirty}
                  size={'sm'}
                  type="submit"
                >
                  Create Team
                </Button>
              </div>
            </div>
          </form>
        </Form>
      </div>
    </div>
  );
};

export default SettingNewTeamPage;
