import { useContext, useEffect, useState } from 'react';

import SubscriptionContext from '@/providers/subscriptions/SubscriptionContext';
import {
  PushEventType,
  DocumentAttachmentFragment,
} from '@/types/tanstack-query/generated';

const useDocumentAttachments = () => {
  const { pubsub } = useContext(SubscriptionContext);
  const [attachments, setAttachments] = useState<DocumentAttachmentFragment[]>(
    [],
  );
  const storeAttachments = async (value: DocumentAttachmentFragment[]) => {
    setAttachments(value);
  };

  const pushItem = async (props: {
    attachment: DocumentAttachmentFragment;
  }) => {
    const clone = Object.assign([], attachments);
    clone.push(props.attachment);
    setAttachments(clone);
  };

  const popItem = async (props: { attachment_id: string }) => {
    const clone: DocumentAttachmentFragment[] = Object.assign(
      [],
      attachments.filter((item) => item.id !== props.attachment_id),
    );
    setAttachments(clone);
  };

  useEffect(() => {
    if (pubsub && pubsub.event && pubsub.id) {
      switch (pubsub.event) {
        case PushEventType.DocumentAttachmentAdded:
          if (!pubsub.data) return;
          pushItem({ attachment: pubsub.data as DocumentAttachmentFragment });
          break;
        case PushEventType.DocumentAttachmentDeleted:
          popItem({ attachment_id: pubsub.id });
          break;
        default:
          break;
      }
    }
  }, [pubsub]);

  return {
    attachments,

    event: {
      storeAttachments,
    },
  };
};

export default useDocumentAttachments;
