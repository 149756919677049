import { useState } from 'react';

import { CreateAppointmentDialog } from '@/pages/appointment/components/CreateAppointment/CreateAppointmentDialog';

import AppointmentServicesContext from './AppointmentServicesContext';
import useProvider from './useProvider';

const AppointmentServicesWrapper = ({
  children,
}: {
  children?: React.ReactNode;
}) => {
  const { isShowCreateAppointment, event } = useProvider();
  const [initCreate, setInitCreate] = useState<{
    initDate?: Date;
    initIssueId?: string;
  }>();
  return (
    <AppointmentServicesContext.Provider
      value={{
        isShowCreateAppointment,
        showCreateAppointmentPopup: (value) => {
          event.showCreateAppointmentPopup(value.isOpen);
          setInitCreate({ ...value });
        },
      }}
    >
      {children}

      <CreateAppointmentDialog
        {...initCreate}
        onSuccess={() => {}}
        onCancel={() => {}}
      />
    </AppointmentServicesContext.Provider>
  );
};

export default AppointmentServicesWrapper;
