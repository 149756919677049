import { createContext } from 'react';

type TAppointmentServicesContext = {
  isShowCreateAppointment: boolean;
  showCreateAppointmentPopup: (props: {
    isOpen: boolean;
    initDate?: Date;
    initIssueId?: string;
  }) => void;
};

const AppointmentServicesContext = createContext<TAppointmentServicesContext>({
  isShowCreateAppointment: false,
  showCreateAppointmentPopup: () => undefined,
});

export default AppointmentServicesContext;
