import { Navigate, RouteObject } from 'react-router-dom';

import AppointmentCalendarViewPage from '@/pages/appointment/calendar-view';
import AppointmentListViewPage from '@/pages/appointment/list-view';
import DocumentDetailPage from '@/pages/document';
import ProjectIssuesPage from '@/pages/project/issues';
import ProjectOverviewPage from '@/pages/project/overview';
import DocumentsPage from '@/pages/team/documents';
import ProjectsPage from '@/pages/team/projects';

import InboxPage from '../../pages/inbox/index';
import IssueDetailPage from '../../pages/issue/detail/index';
import IssuesActivityPage from '../../pages/issue/my-issue/activity';
import IssuesAssignedPage from '../../pages/issue/my-issue/assigned/index';
import IssuesCreatedPage from '../../pages/issue/my-issue/created/index';
import IssuesSubscribedPage from '../../pages/issue/my-issue/subscribed/index';
import SearchIssuePage from '../../pages/search';
import TeamActivesPage from '../../pages/team/actives/index';
import TeamBacklogPage from '../../pages/team/backlog/index';
import TeamIssuesPage from '../../pages/team/issues';
import TeamsPage from '../../pages/teams';

const AuthIssueRoute: RouteObject[] = [
  {
    path: ':workspace/my-issues',
    children: [
      {
        path: '',
        element: <Navigate to="assigned" />,
      },
      {
        path: 'assigned',
        element: <IssuesAssignedPage />,
      },
      {
        path: 'created',
        element: <IssuesCreatedPage />,
      },
      {
        path: 'activity',
        element: <IssuesActivityPage />,
      },
      {
        path: 'subscribed',
        element: <IssuesSubscribedPage />,
      },
    ],
  },
  {
    path: ':workspace/search',
    element: <SearchIssuePage />,
  },
  {
    path: ':workspace/inbox',
    element: <InboxPage />,
  },

  {
    path: ':workspace/appointments',
    children: [
      {
        path: '',
        element: <Navigate to="list" />,
      },
      {
        path: 'list',
        element: <AppointmentListViewPage />,
      },
      {
        path: 'calendar',
        element: <AppointmentCalendarViewPage />,
      },
    ],
  },
  {
    path: ':workspace/inbox/:id',
    element: <InboxPage />,
  },
  {
    path: ':workspace/issue/:identifier',
    element: <IssueDetailPage />,
  },
  {
    path: ':workspace/teams',
    element: <TeamsPage />,
  },

  {
    path: ':workspace/document/:id',
    element: <DocumentDetailPage />,
  },
  {
    path: ':workspace/team/:team',
    children: [
      {
        path: '',
        element: <Navigate to="issues" />,
      },
      {
        path: 'issues',
        element: <TeamIssuesPage />,
      },
      {
        path: 'active',
        element: <TeamActivesPage />,
      },
      {
        path: 'backlog',
        element: <TeamBacklogPage />,
      },
      {
        path: 'documents',
        element: <DocumentsPage />,
      },
      {
        path: 'projects',
        element: <ProjectsPage />,
      },
    ],
  },

  {
    path: ':workspace/project/:projectCode',
    children: [
      {
        path: '',
        element: <Navigate to="overview" />,
      },
      {
        path: 'overview',
        element: <ProjectOverviewPage />,
      },
      {
        path: 'issues',
        element: <ProjectIssuesPage />,
      },
    ],
  },
];

export default AuthIssueRoute;
