import React, { useContext } from 'react';

import WorkspaceContext from '@/providers/workspace/WorkspaceContext';
import { AppointmentFragment } from '@/types/tanstack-query/generated';

const RenderAppointment = (props: {
  id: string | undefined;
  renderItem: (value: AppointmentFragment | undefined) => React.ReactNode;
}) => {
  if (!props.id) {
    return <>{props.renderItem(undefined)}</>;
  }
  const { appointments } = useContext(WorkspaceContext);
  return (
    <>{props.renderItem(appointments.find((item) => item.id === props.id))}</>
  );
};

const RenderAppointmentsByIssueId = (props: {
  id: string;
  renderItem: (value: AppointmentFragment[]) => React.ReactNode;
}) => {
  const { appointments } = useContext(WorkspaceContext);
  return (
    <>
      {props.renderItem(
        appointments.filter((item) => item.issue_id === props.id),
      )}
    </>
  );
};

const MemoizedRenderAppointmentsByIssueId = React.memo(
  RenderAppointmentsByIssueId,
);
const MemoizedRenderAppointment = React.memo(RenderAppointment);
export { MemoizedRenderAppointment, MemoizedRenderAppointmentsByIssueId };
