import { AppointmentStatus } from "../generated";

export type IAppointmentVisible = 'private' | 'public';
export type IItemAppointmentVisible = {
  id: string;
  name: string;
  value: IAppointmentVisible;
}
export type IItemAppointmentStatus = {
  id: string;
  name: string;
  value: AppointmentStatus;
}

export const AppointmentVisibleList: IItemAppointmentVisible[] = [
  {
    id: 'private',
    name: 'Private',
    value: 'private',
  },
  {
    id: 'public',
    name: 'Public',
    value: 'public',
  },
];

export const AppointmentStatusList: IItemAppointmentStatus[] = [
  {
    id: 'processing',
    name: 'Processing',
    value: AppointmentStatus.Processing,
  },
  {
    id: 'completed',
    name: 'Completed',
    value: AppointmentStatus.Completed,
  },
  {
    id: 'cancelled',
    name: 'Cancelled',
    value: AppointmentStatus.Cancelled,
  },
];
