import React, { useContext } from 'react';

import { Dialog, DialogContent } from '@/components/Dialog';
import AppointmentServicesContext from '@/providers/appointment/AppointmentServicesContext';

import { CreateAppointmentComponent } from './CreateAppointmentComponent';

export const CreateAppointmentDialog = (props: {
  initDate?: Date;
  initIssueId?: string;
  onSuccess: () => void;
  onCancel: () => void;
}) => {
  const { isShowCreateAppointment, showCreateAppointmentPopup } = useContext(
    AppointmentServicesContext,
  );

  return (
    <>
      <Dialog
        modal={true}
        open={isShowCreateAppointment}
        onOpenChange={(value) => showCreateAppointmentPopup({ isOpen: value })}
      >
        <DialogContent
          className="flex max-w-[820px] flex-col gap-2"
          style={{ padding: 0 }}
        >
          <CreateAppointmentComponent {...props} />
        </DialogContent>
      </Dialog>
    </>
  );
};
