import { yupResolver } from '@hookform/resolvers/yup';
import { useForm } from 'react-hook-form';
import * as yup from 'yup';

import { Button } from '@/components/Button';
import { Card } from '@/components/Card';
import { Form, FormField } from '@/components/Form';
import { Input } from '@/components/Input';
import { LabelError } from '@/components/LabelError';
import { useErrorHandler } from '@/hooks/useToasterHandler';
import {
  LabelFragment,
  useUpdateLabelMutation,
} from '@/types/tanstack-query/generated';

import ColorPicker from '../../../components/ColorPicker';

export type IUpdateLabel = {
  name: string;
  color: string;
};

export const schemaUpdateLabel = yup.object().shape({
  name: yup.string().required(),
  color: yup.string().required(),
});

const UpdateLabelForm = (props: {
  label: LabelFragment;
  onSuccess: (data: LabelFragment) => void;
  onCancel: () => void;
}) => {
  const { showErrorToast } = useErrorHandler();
  const updateLabel = useUpdateLabelMutation({
    onMutate: () => {},
    onError: (res) => {
      showErrorToast({ error: res });
    },
    onSuccess: (res) => {
      props.onSuccess(res.updateLabel);
    },
    onSettled: () => {},
  });

  const form = useForm<IUpdateLabel>({
    resolver: yupResolver<IUpdateLabel>(schemaUpdateLabel),
    defaultValues: {
      name: props.label.name,
      color: props.label.color,
    },
  });
  const onSubmitUpdate = (data: IUpdateLabel) => {
    updateLabel.mutate({
      updateInput: {
        id: props.label.id,
        name: data.name,
        color: data.color,
      },
    });
  };

  return (
    <Card className="animate-fade-down animate-delay-0 animate-duration-100 animate-ease-in">
      <Form {...form}>
        <form onSubmit={form.handleSubmit(onSubmitUpdate)}>
          <div className="flex flex-row items-center gap-4">
            <ColorPicker
              onSelected={(data) => form.setValue('color', data)}
              color={form.watch('color')}
            >
              <Button size={'sm'} type="button" variant={'secondary'}>
                <span
                  style={{ backgroundColor: form.watch('color') }}
                  className={`inline-block h-[10px] w-[10px] rounded-full`}
                ></span>
              </Button>
            </ColorPicker>

            <div className="w-full">
              <FormField
                control={form.control}
                name="name"
                render={({ field }) => (
                  <>
                    <Input {...field} placeholder="Label name"></Input>

                    <LabelError>
                      {form.formState.errors.name?.message}
                    </LabelError>
                  </>
                )}
              />
            </div>
            <Button
              variant={'secondary'}
              type="button"
              size={'sm'}
              onClick={props.onCancel}
            >
              Cancel
            </Button>
            <Button size={'sm'} disabled={updateLabel.isPending} type="submit">
              Save
            </Button>
          </div>
        </form>
      </Form>
    </Card>
  );
};

export default UpdateLabelForm;
