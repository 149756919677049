/* eslint-disable import/no-extraneous-dependencies */

import React, { useEffect, useState } from 'react';

import { Value } from '@udecode/plate';
import { Plate } from '@udecode/plate/react';

import { Editor, EditorContainer } from '@/components/plate-ui/editor';
import { TooltipProvider } from '@/components/plate-ui/tooltip';
import { useDebounce } from '@/hooks/use-debounce';
import PlateUtils from '@/utils/plate-utils';

import useSingleLineEditor from './useSingleLineEditor';

const SingleLineEditorComponent = React.memo(
  (props: {
    id?: string;
    content?: string;
    placeholder?: string;
    readOnly?: boolean;
    disabled?: boolean;
    className?: string;
    onSave?: (content: string | undefined) => void;
    onDraft?: (value: boolean) => void;
  }) => {
    const editor = useSingleLineEditor(props.id ?? '');
    const [draftContent, setDraftContent] = useState<Value>();
    const debounceInput = useDebounce(draftContent, 500);

    const onVerifyDraft = () => {
      const getContent = PlateUtils.getTitleFromTElement(editor.children);
      if (props.content?.length !== getContent?.length) {
        props.onDraft?.(true);
      } else {
        props.onDraft?.(false);
      }
    };

    useEffect(() => {
      if (debounceInput) {
        try {
          const getContent = PlateUtils.getTitleFromTElement(debounceInput);
          if (getContent?.length === props.content?.length) return;
          props.onSave?.(getContent);
        } catch (error) {
          console.log(error);
        }
      }
    }, [debounceInput]);

    useEffect(() => {
      try {
        if (props.content) {
          editor.tf.setValue(PlateUtils.setStringToTElement(props.content));
        } else {
          editor.tf.setValue([]);
        }
      } catch (error) {
        console.log(error);
      }
    }, [props.id]);

    useEffect(() => {
      onVerifyDraft();
    }, [props.content]);

    return (
      <div className="relative w-full">
        <TooltipProvider>
          <Plate
            editor={editor}
            onChange={() => {
              onVerifyDraft();
            }}
          >
            <EditorContainer>
              <Editor
                readOnly={props.readOnly}
                disabled={props.disabled}
                placeholder={props.placeholder}
                style={{ padding: 0 }}
                onBlur={() => {
                  onVerifyDraft();
                  setDraftContent(editor.children);
                }}
                className={props.className}
              />
            </EditorContainer>
          </Plate>
        </TooltipProvider>
      </div>
    );
  },
);

export default SingleLineEditorComponent;
