import { useContext } from 'react';

import { ArrowRightIcon, CalendarX2Icon, PackageIcon } from 'lucide-react';
import { useNavigate } from 'react-router-dom';

import { Button } from '@/components/Button';
import { Label } from '@/components/Label';
import { TooltipRoot } from '@/components/Tooltip';
import { UserAvatar } from '@/components/UserAvatar';
import { useErrorHandler } from '@/hooks/useToasterHandler';
import { UserAssignIcon } from '@/icons/core/issue';
import WorkspaceContext from '@/providers/workspace/WorkspaceContext';
import { MemoizedRenderIssue } from '@/renders/RenderIssue';
import { MemoizedRenderLabels } from '@/renders/RenderLabel';
import { MemoizedRenderProject } from '@/renders/RenderProject';
import { MemoizedRenderTeam } from '@/renders/RenderTeam';
import {
  PriorityType,
  IssueFragment,
  TeamFragment,
  useUpdateIssueMutation,
  WorkspaceFragment,
} from '@/types/tanstack-query/generated';
import { IIssueListType } from '@/types/tanstack-query/issue/issue.interface';
import { match } from '@/utils/custom-matcher';
import CustomDateTime from '@/utils/format-datetime';
import { cn } from '@/utils/utils';

import { ToolsMenuIssue } from '../ContextMenuIssue';
import MemoizedAssignToButton from '../Properties/AssignToButton';
import MemoizedDueDateButton from '../Properties/DueDateButton';
import {
  MemoizedPriorityButton,
  MemoizedRenderPriorityIcon,
} from '../Properties/PriorityButton';
import {
  MemoizedRenderStatusIcon,
  MemoizedStatusButton,
} from '../Properties/StatusButton';
import { ParentIssuesCircleButton } from '../Properties/SubIssuesButton';

const IssueRowItem = (props: {
  issue: IssueFragment;
  workspace: WorkspaceFragment;
  team: TeamFragment;
  title: string;
  onNavigate: (path: string) => void;
  onSelectedWorkflowId: (value: string | undefined) => void;
  onSelectedPriority: (value: PriorityType | undefined) => void;
  onSelectedLabelIds: (value: string[]) => void;
  onSelectedMemberId: (value: string | undefined) => void;
  onSelectedDueDateAt: (value: Date | undefined) => void;
}) => {
  return (
    <div className="border-bottom flex h-[50px] w-full cursor-pointer items-center gap-2 overflow-hidden border-[1px] border-secondary hover:bg-secondary">
      <div className="hidden w-[30px] flex-none items-center justify-center lg:flex">
        <MemoizedPriorityButton
          title={props.title}
          selected={props.issue.priority}
          onSelected={props.onSelectedPriority}
          renderItem={(value) => (
            <Button size={'icon'} variant={'ghost'} type="button">
              <MemoizedRenderPriorityIcon type={value?.type} />
            </Button>
          )}
        />
      </div>
      <div
        className="hidden w-[60px] flex-none items-center justify-start lg:flex"
        onClick={() =>
          props.onNavigate(
            `/${props.workspace.url}/issue/${props.team.identifier}-${props.issue.code}`,
          )
        }
      >
        <Label>{`${props.team.identifier}-${props.issue.code}`}</Label>
      </div>

      <div className="flex w-[30px] flex-none items-center justify-center">
        <MemoizedStatusButton
          title={props.title}
          teamId={props.issue.team_id ?? ''}
          selected={props.issue.workflow_id ?? undefined}
          onSelected={props.onSelectedWorkflowId}
          renderItem={(value) => (
            <Button size={'icon'} variant={'ghost'} type="button">
              <MemoizedRenderStatusIcon
                type={value?.type}
                color={value?.color}
                processing={value?.processing}
              />
            </Button>
          )}
        />
      </div>

      <div
        className="flex w-full flex-row gap-2"
        onClick={() =>
          props.onNavigate(
            `/${props.workspace.url}/issue/${props.team.identifier}-${props.issue.code}`,
          )
        }
      >
        <div className="flex flex-row items-center gap-2">
          <Label asChild className="line-clamp-1 flex-auto">
            <span>{props.issue.title}</span>
          </Label>
          <div className="hidden flex-row   items-center gap-2 lg:flex">
            {props.issue.issue_id && (
              <MemoizedRenderIssue
                id={props.issue.issue_id}
                renderItem={(parent) => (
                  <>
                    <ArrowRightIcon
                      className="flex-none opacity-50"
                      width={14}
                      height={14}
                    />
                    <div className="inline-flex min-w-[50px] flex-shrink-[10]">
                      <Label className="line-clamp-1 flex-auto opacity-50">
                        {parent?.title}
                      </Label>
                    </div>
                  </>
                )}
              />
            )}
          </div>
          <div className="flex-none">
            <ParentIssuesCircleButton id={props.issue.id} />
          </div>
        </div>

        <MemoizedRenderLabels
          ids={props.issue.labels?.map((item) => item.label_id) ?? []}
          renderItem={(labels) =>
            labels.length > 0 && (
              <div className="flex flex-none flex-row gap-2">
                {labels.map((label) => (
                  <Button
                    key={label.id}
                    type="button"
                    size={'sm'}
                    variant={'outline'}
                    className="flex flex-row items-center gap-2 truncate rounded-full "
                  >
                    <span
                      className="inline-block h-[10px] w-[10px] rounded-full"
                      style={{ backgroundColor: label.color }}
                    ></span>
                    <Label className="flex h-[27px] items-center text-xs">
                      <span className="max-w-[100px] truncate">
                        {label.name}
                      </span>
                    </Label>
                  </Button>
                ))}
              </div>
            )
          }
        />
        <MemoizedRenderProject
          id={props.issue.project_id ?? ''}
          renderItem={(project) =>
            project && (
              <div className="flex flex-none flex-row gap-2">
                <Button
                  key={project.id}
                  type="button"
                  size={'sm'}
                  variant={'outline'}
                  className="flex flex-row items-center gap-2 truncate rounded-full "
                >
                  <PackageIcon width={14} height={14} />
                  <Label className="flex h-[27px] items-center text-xs">
                    <span className="max-w-[100px] truncate">
                      {project.title}
                    </span>
                  </Label>
                </Button>
              </div>
            )
          }
        />
      </div>

      <div className="hidden w-[100px] flex-none items-center justify-end gap-2 lg:flex">
        {props.issue.created_at && (
          <TooltipRoot
            content={`Created at ${CustomDateTime.convertStringToDateTime(
              props.issue.created_at,
            )}`}
          >
            <Label className="max-w-[60px] flex-none text-xs">
              {CustomDateTime.convertStringToDateTime(
                props.issue.created_at,
                'LLL dd',
              )}
            </Label>
          </TooltipRoot>
        )}
        {props.issue.updated_at && (
          <TooltipRoot
            content={`Updated at ${CustomDateTime.convertStringToDateTime(
              props.issue.updated_at,
            )}`}
          >
            <Label className="max-w-[60px] flex-none text-xs">
              {CustomDateTime.convertStringToDateTime(
                props.issue.updated_at,
                'LLL dd',
              )}
            </Label>
          </TooltipRoot>
        )}
      </div>
      {props.issue.due_date_at && (
        <div className="flex-none">
          <MemoizedDueDateButton
            title={props.title}
            selected={
              props.issue.due_date_at
                ? new Date(props.issue.due_date_at)
                : undefined
            }
            onSelected={props.onSelectedDueDateAt}
            renderItem={(value) => (
              <Button
                variant={'outline'}
                className={cn(
                  'justify-start text-left font-normal',
                  !value && 'text-muted-foreground',
                )}
              >
                <CalendarX2Icon
                  className="mr-2"
                  width={14}
                  height={14}
                  color={'red'}
                />
                <Label className="flex h-[27px] items-center text-xs">
                  {value ? CustomDateTime.formatDate(value) : 'Pick a Due date'}
                </Label>
              </Button>
            )}
          />
        </div>
      )}

      <div className="w-[30px] flex-none">
        <MemoizedAssignToButton
          title={props.title}
          teamIds={props.issue.team_id ? [props.issue.team_id] : []}
          selected={props.issue.assignee_id ?? undefined}
          onSelected={props.onSelectedMemberId}
          renderItem={(value) => (
            <Button size={'icon'} variant={'ghost'} type="button">
              <div className="flex w-full items-center justify-center">
                {value && <UserAvatar size={'sm'} fallback={value?.username} />}
                {!value && (
                  <UserAssignIcon
                    width={22}
                    height={22}
                    style={{ color: '#9e9fa8' }}
                  />
                )}
              </div>
            </Button>
          )}
        />
      </div>
    </div>
  );
};
const SubIssueRowItem = (props: {
  issue: IssueFragment;
  workspace: WorkspaceFragment;
  team: TeamFragment;
  title: string;
  onNavigate: (path: string) => void;
  onSelectedWorkflowId: (value: string | undefined) => void;
  onSelectedPriority: (value: PriorityType | undefined) => void;
  onSelectedLabelIds: (value: string[]) => void;
  onSelectedMemberId: (value: string | undefined) => void;
  onSelectedDueDateAt: (value: Date | undefined) => void;
}) => {
  return (
    <div className="border-bottom flex h-[50px] w-full cursor-pointer items-center gap-2 overflow-hidden border-[1px] border-secondary hover:bg-secondary">
      <div className="flex w-[30px] flex-none items-center justify-center">
        <MemoizedStatusButton
          title={props.title}
          teamId={props.issue.team_id ?? ''}
          selected={props.issue.workflow_id ?? undefined}
          onSelected={props.onSelectedWorkflowId}
          renderItem={(value) => (
            <Button size={'icon'} variant={'ghost'} type="button">
              <MemoizedRenderStatusIcon
                type={value?.type}
                color={value?.color}
                processing={value?.processing}
              />
            </Button>
          )}
        />
      </div>

      <div className="flex w-[60px] flex-none items-center justify-start">
        <Label>{`${props.team.identifier}-${props.issue.code}`}</Label>
      </div>

      <div
        className="flex w-full grow flex-row items-center justify-between"
        onClick={() =>
          props.onNavigate(
            `/${props.workspace.url}/issue/${props.team.identifier}-${props.issue.code}`,
          )
        }
      >
        <Label className="line-clamp-1 text-ellipsis">
          {props.issue.title}
        </Label>
        <div className="flex flex-none flex-row gap-2">
          <MemoizedRenderLabels
            ids={props.issue.labels?.map((item) => item.label_id) ?? []}
            renderItem={(labels) =>
              labels.map((label) => (
                <Button
                  key={label.id}
                  type="button"
                  size={'sm'}
                  variant={'outline'}
                  className="flex flex-row items-center gap-2 truncate rounded-full "
                >
                  <span
                    className="inline-block h-[10px] w-[10px] rounded-full"
                    style={{ backgroundColor: label.color }}
                  ></span>
                  <Label className="flex h-[27px] items-center text-xs">
                    <span className="max-w-[100px] truncate">{label.name}</span>
                  </Label>
                </Button>
              ))
            }
          />
        </div>
      </div>

      <div className="hidden w-[30px] flex-none items-center justify-center lg:flex">
        <MemoizedPriorityButton
          title={props.title}
          selected={props.issue.priority}
          onSelected={props.onSelectedPriority}
          renderItem={(value) => (
            <Button size={'icon'} variant={'ghost'} type="button">
              <MemoizedRenderPriorityIcon type={value?.type} />
            </Button>
          )}
        />
      </div>

      <div className="hidden w-[100px] flex-none items-center justify-end gap-2 lg:flex">
        {props.issue.created_at && (
          <TooltipRoot
            content={`Created at ${CustomDateTime.convertStringToDateTime(
              props.issue.created_at,
            )}`}
          >
            <Label className="max-w-[60px] flex-none text-xs">
              {CustomDateTime.convertStringToDateTime(
                props.issue.created_at,
                'LLL dd',
              )}
            </Label>
          </TooltipRoot>
        )}
        {props.issue.updated_at && (
          <TooltipRoot
            content={`Updated at ${CustomDateTime.convertStringToDateTime(
              props.issue.updated_at,
            )}`}
          >
            <Label className="max-w-[60px] flex-none text-xs">
              {CustomDateTime.convertStringToDateTime(
                props.issue.updated_at,
                'LLL dd',
              )}
            </Label>
          </TooltipRoot>
        )}
      </div>

      <div className="flex-none">
        <MemoizedDueDateButton
          title={props.title}
          selected={
            props.issue.due_date_at
              ? new Date(props.issue.due_date_at)
              : undefined
          }
          onSelected={props.onSelectedDueDateAt}
          renderItem={(value) => (
            <Button
              variant={'outline'}
              className={cn(
                'justify-start text-left font-normal',
                !value && 'text-muted-foreground',
              )}
            >
              <CalendarX2Icon className="mr-2 h-4 w-4" />
              {value ? CustomDateTime.formatDate(value) : 'Pick a Due date'}
            </Button>
          )}
        />
      </div>

      <div className="w-[30px] flex-none">
        <MemoizedAssignToButton
          title={props.title}
          teamIds={props.issue.team_id ? [props.issue.team_id] : []}
          selected={props.issue.assignee_id ?? undefined}
          onSelected={props.onSelectedMemberId}
          renderItem={(value) => (
            <Button size={'icon'} variant={'ghost'} type="button">
              <div className="flex w-[30px] items-center justify-center">
                {value && <UserAvatar size={'sm'} fallback={value?.username} />}
                {!value && (
                  <UserAssignIcon
                    width={22}
                    height={22}
                    style={{ color: '#9e9fa8' }}
                  />
                )}
              </div>
            </Button>
          )}
        />
      </div>
    </div>
  );
};
const IssueTableList = (props: {
  data: IssueFragment[];
  type: IIssueListType;
  onNavigate?: (path: string) => void;
}) => {
  const { selected } = useContext(WorkspaceContext);
  const { showErrorToast } = useErrorHandler();
  const navigate = useNavigate();
  const onNavigate = (path: string) => {
    navigate(path);
    props.onNavigate?.(path);
  };

  const updateIssue = useUpdateIssueMutation({
    onMutate: () => {},
    onError: (res) => {
      showErrorToast({ error: res });
    },
    onSuccess: () => {},
    onSettled: () => {},
  });

  return (
    <div className="w-full">
      {selected &&
        props.data.map(
          (item) =>
            item && (
              <ToolsMenuIssue
                contextMenu={true}
                key={item.id}
                issue={item}
                workspace_id={item.workspace_id}
              >
                <MemoizedRenderTeam
                  id={item.team_id ?? undefined}
                  renderItem={(team) =>
                    team &&
                    match([props.type])({
                      issues: () => (
                        <IssueRowItem
                          key={item.id}
                          issue={item}
                          team={team}
                          title={`${team.identifier}-${item.code} ${item?.title}`}
                          workspace={selected}
                          onNavigate={onNavigate}
                          onSelectedWorkflowId={(value) =>
                            updateIssue.mutate({
                              updateInput: {
                                id: item.id,
                                workflow_id: value,
                              },
                            })
                          }
                          onSelectedPriority={(value) =>
                            updateIssue.mutate({
                              updateInput: {
                                id: item.id,
                                priority: value,
                              },
                            })
                          }
                          onSelectedLabelIds={(value) =>
                            updateIssue.mutate({
                              updateInput: {
                                id: item.id,
                                label_ids: value,
                              },
                            })
                          }
                          onSelectedMemberId={(value) =>
                            updateIssue.mutate({
                              updateInput: {
                                id: item.id,
                                assignee_id: value,
                              },
                            })
                          }
                          onSelectedDueDateAt={(value) =>
                            updateIssue.mutate({
                              updateInput: {
                                id: item.id,
                                due_date_at: value?.toISOString(),
                              },
                            })
                          }
                        />
                      ),
                      'sub-issues': () => (
                        <SubIssueRowItem
                          key={item.id}
                          issue={item}
                          team={team}
                          title={`${team.identifier}-${item.code} ${item?.title}`}
                          workspace={selected}
                          onNavigate={onNavigate}
                          onSelectedWorkflowId={(value) =>
                            updateIssue.mutate({
                              updateInput: {
                                id: item.id,
                                workflow_id: value,
                              },
                            })
                          }
                          onSelectedPriority={(value) =>
                            updateIssue.mutate({
                              updateInput: {
                                id: item.id,
                                priority: value,
                              },
                            })
                          }
                          onSelectedLabelIds={(value) =>
                            updateIssue.mutate({
                              updateInput: {
                                id: item.id,
                                label_ids: value,
                              },
                            })
                          }
                          onSelectedMemberId={(value) =>
                            updateIssue.mutate({
                              updateInput: {
                                id: item.id,
                                assignee_id: value,
                              },
                            })
                          }
                          onSelectedDueDateAt={(value) =>
                            updateIssue.mutate({
                              updateInput: {
                                id: item.id,
                                due_date_at: value?.toISOString(),
                              },
                            })
                          }
                        />
                      ),
                    })
                  }
                />
              </ToolsMenuIssue>
            ),
        )}
    </div>
  );
};
export default IssueTableList;
