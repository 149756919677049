/* eslint-disable import/no-extraneous-dependencies */
import { v4 as uuidv4 } from 'uuid';

export const generateRandomNumber = (): string => {
  const minm = 100000;
  const maxm = 999999;
  return (Math.floor(Math.random() * (maxm - minm + 1)) + minm).toString();
};

export const generateUuid = () => {
  return uuidv4();
};

export const generateCharacters = (props: { length: number; name: string }) => {
  return props.name.slice(0, props.length).toUpperCase();
};
