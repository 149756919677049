import { useContext } from 'react';

import { StarFilledIcon } from '@radix-ui/react-icons';
import {
  BellOff,
  BellRing,
  BlocksIcon,
  Clipboard,
  Copy,
  CopyIcon,
  HistoryIcon,
  Link,
  StarIcon,
  TrashIcon,
} from 'lucide-react';
import { useCopyToClipboard } from 'usehooks-ts';

import {
  ContextMenu,
  ContextMenuContent,
  ContextMenuItem,
  ContextMenuSeparator,
  ContextMenuSub,
  ContextMenuSubContent,
  ContextMenuSubTrigger,
  ContextMenuTrigger,
} from '@/components/ContextMenu';
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuSeparator,
  DropdownMenuSub,
  DropdownMenuSubContent,
  DropdownMenuSubTrigger,
  DropdownMenuTrigger,
} from '@/components/DropdownMenu';
import { Label } from '@/components/Label';
import { useErrorHandler, useSuccessHandler } from '@/hooks/useToasterHandler';
import IssueContentHistoryContext from '@/providers/issue/IssueContentHistory/IssueServicesContext';
import IssueDeleteContext from '@/providers/issue/IssueDelete/IssueDeleteContext';
import useIssueServices from '@/providers/issue/useIssueServices';
import WorkspaceContext from '@/providers/workspace/WorkspaceContext';
import { MemoizedRenderFavorite } from '@/renders/RenderFavorite';
import { MemoizedRenderSubscriber } from '@/renders/RenderSubscriber';
import { DOMAIN_URL } from '@/services/core/constant';
import {
  FavoriteType,
  IssueFragment,
  SubscriberType,
  useSubscribeMutation,
  useToggleFavoriteMutation,
} from '@/types/tanstack-query/generated';
import { Delay } from '@/utils/delay';

const MenuItemIcon = (props: { title: string; icon: React.ReactNode }) => {
  return (
    <div className="flex flex-row items-center gap-2">
      <div className="flex w-[20px] flex-none items-center">{props.icon}</div>
      <Label>{props.title}</Label>
    </div>
  );
};

export const ContextMenuIssue = (props: {
  issue_id: string;
  workspace_id: string;
  className?: string;
  children: React.ReactNode;
  onToggleFavorite: () => void;
  onToggleSubscribe: () => void;
  onShowVersions: () => void;
  onShowDelete: () => void;
  onCopyId: () => void;
  onCopyTitle: () => void;
  onCopyLink: () => void;
  onCopyTitleAsLink: () => void;
  onMakeACopy: () => void;
}) => {
  return (
    <ContextMenu modal>
      <ContextMenuTrigger asChild>
        <div className={props.className}>{props.children}</div>
      </ContextMenuTrigger>
      <ContextMenuContent>
        <MemoizedRenderSubscriber
          map_id={props.issue_id}
          type={SubscriberType.Issue}
          renderItem={(subscriber) =>
            subscriber ? (
              <ContextMenuItem onClick={() => props.onToggleSubscribe()}>
                <MenuItemIcon icon={<BellOff />} title="Unsubscribe" />
              </ContextMenuItem>
            ) : (
              <ContextMenuItem onClick={() => props.onToggleSubscribe()}>
                <MenuItemIcon icon={<BellRing />} title="Subscribe" />
              </ContextMenuItem>
            )
          }
        />
        <MemoizedRenderFavorite
          map_id={props.issue_id}
          type={FavoriteType.Issue}
          renderItem={(favorite) =>
            favorite ? (
              <ContextMenuItem onClick={props.onToggleFavorite}>
                <MenuItemIcon
                  icon={<StarFilledIcon />}
                  title="Remove from favorites"
                />
              </ContextMenuItem>
            ) : (
              <ContextMenuItem onClick={props.onToggleFavorite}>
                <MenuItemIcon icon={<StarIcon />} title="Favorite" />
              </ContextMenuItem>
            )
          }
        />
        <ContextMenuItem onClick={() => props.onMakeACopy()}>
          <MenuItemIcon icon={<CopyIcon />} title="Make a Copy" />
        </ContextMenuItem>
        <ContextMenuSub>
          <ContextMenuSubTrigger onClick={props.onCopyId}>
            <MenuItemIcon icon={<CopyIcon />} title="Copy" />
          </ContextMenuSubTrigger>
          <ContextMenuSubContent>
            <ContextMenuItem onClick={props.onCopyId}>
              <MenuItemIcon icon={<Copy />} title="Copy ID" />
            </ContextMenuItem>
            <ContextMenuItem onClick={props.onCopyTitle}>
              <MenuItemIcon icon={<BlocksIcon />} title="Copy title" />
            </ContextMenuItem>
            <ContextMenuItem onClick={props.onCopyLink}>
              <MenuItemIcon icon={<Link />} title="Copy link" />
            </ContextMenuItem>
            <ContextMenuItem onClick={props.onCopyTitleAsLink}>
              <MenuItemIcon icon={<Clipboard />} title="Copy title as link" />
            </ContextMenuItem>
          </ContextMenuSubContent>
        </ContextMenuSub>
        <ContextMenuSeparator />

        <ContextMenuItem onClick={props.onShowVersions}>
          <MenuItemIcon icon={<HistoryIcon />} title="Issue content history" />
        </ContextMenuItem>

        <ContextMenuItem onClick={props.onShowDelete}>
          <MenuItemIcon icon={<TrashIcon />} title="Delete" />
        </ContextMenuItem>
      </ContextMenuContent>
    </ContextMenu>
  );
};

export const DropdownMenuIssue = (props: {
  issue_id: string;
  workspace_id: string;
  className?: string;
  children: React.ReactNode;
  onToggleFavorite: () => void;
  onToggleSubscribe: (isSubscribe: boolean) => void;
  onShowVersions: () => void;
  onShowDelete: () => void;
  onCopyId: () => void;
  onCopyTitle: () => void;
  onCopyLink: () => void;
  onCopyTitleAsLink: () => void;
  onMakeACopy: () => void;
}) => {
  return (
    <DropdownMenu modal>
      <DropdownMenuTrigger asChild>
        <div className={props.className}>{props.children}</div>
      </DropdownMenuTrigger>
      <DropdownMenuContent>
        <MemoizedRenderSubscriber
          map_id={props.issue_id}
          type={SubscriberType.Issue}
          renderItem={(subscriber) =>
            subscriber ? (
              <DropdownMenuItem onClick={() => props.onToggleSubscribe(false)}>
                <MenuItemIcon icon={<BellOff />} title="Unsubscribe" />
              </DropdownMenuItem>
            ) : (
              <DropdownMenuItem onClick={() => props.onToggleSubscribe(true)}>
                <MenuItemIcon icon={<BellRing />} title="Subscribe" />
              </DropdownMenuItem>
            )
          }
        />
        <MemoizedRenderFavorite
          map_id={props.issue_id}
          type={FavoriteType.Issue}
          renderItem={(favorite) =>
            favorite ? (
              <DropdownMenuItem onClick={props.onToggleFavorite}>
                <MenuItemIcon
                  icon={<StarFilledIcon />}
                  title="Remove from favorites"
                />
              </DropdownMenuItem>
            ) : (
              <DropdownMenuItem onClick={props.onToggleFavorite}>
                <MenuItemIcon icon={<StarIcon />} title="Favorite" />
              </DropdownMenuItem>
            )
          }
        />
        <DropdownMenuItem onClick={() => props.onMakeACopy()}>
          <MenuItemIcon icon={<CopyIcon />} title="Make a Copy" />
        </DropdownMenuItem>
        <DropdownMenuSub>
          <DropdownMenuSubTrigger>
            <MenuItemIcon icon={<CopyIcon />} title="Copy" />
          </DropdownMenuSubTrigger>
          <DropdownMenuSubContent>
            <DropdownMenuItem onClick={props.onCopyId}>
              <MenuItemIcon icon={<Copy />} title="Copy ID" />
            </DropdownMenuItem>
            <DropdownMenuItem onClick={props.onCopyTitle}>
              <MenuItemIcon icon={<BlocksIcon />} title="Copy title" />
            </DropdownMenuItem>
            <DropdownMenuItem onClick={props.onCopyLink}>
              <MenuItemIcon icon={<Link />} title="Copy link" />
            </DropdownMenuItem>
            <DropdownMenuItem onClick={props.onCopyTitleAsLink}>
              <MenuItemIcon icon={<Clipboard />} title="Copy title as link" />
            </DropdownMenuItem>
          </DropdownMenuSubContent>
        </DropdownMenuSub>
        <DropdownMenuSeparator />

        <DropdownMenuItem onClick={props.onShowVersions}>
          <MenuItemIcon icon={<HistoryIcon />} title="Issue content history" />
        </DropdownMenuItem>

        <DropdownMenuItem onClick={props.onShowDelete}>
          <MenuItemIcon icon={<TrashIcon />} title="Delete" />
        </DropdownMenuItem>
      </DropdownMenuContent>
    </DropdownMenu>
  );
};

export const ToolsMenuIssue = (props: {
  children: React.ReactNode;
  issue: IssueFragment;
  workspace_id: string;
  contextMenu: boolean;
  className?: string;
}) => {
  const { showErrorToast } = useErrorHandler();
  const [, copy] = useCopyToClipboard();
  const { getDataByTeam, selected } = useContext(WorkspaceContext);
  const { showSuccessToast } = useSuccessHandler();

  const { showCreatePopup } = useIssueServices();

  const subscribe = useSubscribeMutation({
    onMutate: () => {},
    onError: (res) => {
      showErrorToast({ error: res });
    },
    onSuccess: () => {},
    onSettled: () => {},
  });

  const toggleFavorite = useToggleFavoriteMutation({
    onMutate: () => {},
    onError: (res) => {
      showErrorToast({ error: res });
    },
    onSuccess: () => {},
    onSettled: () => {},
  });

  const { showModal: showVersionModal } = useContext(
    IssueContentHistoryContext,
  );
  const getTeam =
    getDataByTeam && getDataByTeam({ team_id: props.issue.team_id ?? '' });

  const { showModal: showDeleteModal } = useContext(IssueDeleteContext);

  const onToggleFavorite = () => {
    toggleFavorite.mutate({
      toggleFavorite: {
        map_id: props.issue.id,
        type: FavoriteType.Issue,
        workspace_id: props.workspace_id,
      },
    });
  };

  const onToggleSubscribe = () => {
    subscribe.mutate({
      input: {
        map_id: props.issue.id,
        workspace_id: props.workspace_id,
        type: SubscriberType.Issue,
      },
    });
  };

  const onShowVersions = () => {
    showVersionModal({ isOpen: true, issueId: props.issue.id ?? '' });
  };

  const onShowDelete = () => {
    showDeleteModal({ isOpen: true, issueId: props.issue.id ?? '' });
  };

  const onCopyId = async () => {
    const data = `${getTeam?.team?.identifier}-${props.issue.code}`;
    await copy(data);

    showSuccessToast({
      title: `"${data}" Copied`,
    });
  };

  const onCopyTitle = async () => {
    const data = `${props.issue.title}`;
    await copy(data);
    showSuccessToast({ title: `"${data}" Copied` });
  };

  const onCopyLink = async () => {
    const data = `${DOMAIN_URL}/${selected?.url}/${getTeam?.team?.identifier}-${props.issue.code}`;
    await copy(data);
    showSuccessToast({
      title: `"${data}" Copied`,
    });
  };

  const onCopyTitleAsLink = async () => {
    const link = `${DOMAIN_URL}/${selected?.url}/${getTeam?.team?.identifier}-${props.issue.code}`;

    const data = `[${getTeam?.team?.identifier}-${props.issue.code}] ${props.issue.title} - ${link}`;
    await copy(data);
    showSuccessToast({
      title: `"${data}" Copied`,
    });
  };

  const onMakeACopy = async () => {
    if (!props.issue.team_id) return;
    await Delay(500);
    showCreatePopup({
      isOpen: true,
      initTitle: `${props.issue.title} Copy`,
      initContent: props.issue.description,
      initTeamId: props.issue.team_id,
    });
  };

  return !props.contextMenu ? (
    <DropdownMenuIssue
      onToggleFavorite={onToggleFavorite}
      onToggleSubscribe={onToggleSubscribe}
      onShowVersions={onShowVersions}
      onShowDelete={onShowDelete}
      issue_id={props.issue.id ?? ''}
      workspace_id={props.workspace_id}
      className={props.className}
      onCopyId={onCopyId}
      onCopyTitle={onCopyTitle}
      onCopyLink={onCopyLink}
      onCopyTitleAsLink={onCopyTitleAsLink}
      onMakeACopy={onMakeACopy}
    >
      {props.children}
    </DropdownMenuIssue>
  ) : (
    <ContextMenuIssue
      onToggleFavorite={onToggleFavorite}
      onToggleSubscribe={onToggleSubscribe}
      onShowVersions={onShowVersions}
      onShowDelete={onShowDelete}
      issue_id={props.issue.id ?? ''}
      workspace_id={props.workspace_id}
      className={props.className}
      onCopyId={onCopyId}
      onCopyTitle={onCopyTitle}
      onCopyLink={onCopyLink}
      onCopyTitleAsLink={onCopyTitleAsLink}
      onMakeACopy={onMakeACopy}
    >
      {props.children}
    </ContextMenuIssue>
  );
};
