/* eslint-disable import/no-extraneous-dependencies */

import { useContext, useEffect, useState } from 'react';

import { cn } from '@udecode/cn';
import classNames from 'classnames';

import { Badge } from '@/components/Badge';
import { Button } from '@/components/Button';
import { Calendar } from '@/components/Calendar';
import SingleLineEditorComponent from '@/components/Editor/SingleLineEditor/SingleLineEditorComponent';
import { Label } from '@/components/Label';
import { RadioGroup, RadioGroupItem } from '@/components/Radio';
import { Separator } from '@/components/Separator';
import {
  useErrorHandler,
  useSuccessHandler,
  useWarningHandler,
} from '@/hooks/useToasterHandler';
import AppointmentServicesContext from '@/providers/appointment/AppointmentServicesContext';
import WorkspaceContext from '@/providers/workspace/WorkspaceContext';
import {
  AppointmentStatus,
  useCreateAppointmentMutation,
} from '@/types/tanstack-query/generated';
import { generateUuid } from '@/utils/generate';

import {
  AppointmentVisibleList,
  IAppointmentVisible,
} from '../../../../types/tanstack-query/appointment/appointment.interface';

export const CreateAppointmentComponent = (props: {
  initIssueId?: string;
  initDate?: Date;
  onSuccess: () => void;
  onCancel: () => void;
}) => {
  const { selected: selectedWorkspace } = useContext(WorkspaceContext);
  const { showErrorToast } = useErrorHandler();
  const { showSuccessToast } = useSuccessHandler();
  const { showWarningToast } = useWarningHandler();
  const [date, setDate] = useState<Date | undefined>(
    props.initDate ?? new Date(),
  );

  const [generatedId, setGeneratedId] = useState<string>(generateUuid());
  const [title, setTitle] = useState<string>();

  const { isShowCreateAppointment, showCreateAppointmentPopup } = useContext(
    AppointmentServicesContext,
  );

  const [visible, setVisible] = useState<IAppointmentVisible>('private');

  const createAppointment = useCreateAppointmentMutation({
    onMutate: () => {},
    onError: (res) => {
      showErrorToast({ error: res });
    },
    onSuccess: () => {
      setGeneratedId(generateUuid());
      showCreateAppointmentPopup({ isOpen: false });
      props.onSuccess();
      showSuccessToast({
        title: 'Appointment has been created',
      });
    },
    onSettled: () => {},
  });

  const onSubmitCreate = () => {
    if (selectedWorkspace) {
      if (!title || title?.length === 0) {
        showWarningToast({
          title: 'Title appointment is required',
          description: 'Please add a title before submitting.',
        });
        return;
      }

      createAppointment.mutate({
        createInput: {
          description: title,
          time_at: date?.toISOString() ?? new Date().toISOString(),
          workspace_id: selectedWorkspace?.id,
          issue_id: props.initIssueId ?? null,
          status: AppointmentStatus.Processing,
          is_public: visible === 'public',
        },
      });
    }
  };

  const onReset = () => {
    setGeneratedId(generateUuid());
  };

  const onInitForm = () => {};

  useEffect(() => {
    if (!isShowCreateAppointment) {
      onReset();
    } else {
      onInitForm();
    }
  }, [isShowCreateAppointment]);

  return (
    <>
      <div className="flex-none">
        <div className="flex items-center gap-2 p-[10px]">
          <Label className="font-bold">New appointment</Label>
        </div>
      </div>

      <div
        className={classNames(
          'flex min-h-[200px] w-full grow flex-col gap-2 overflow-auto px-[10px]',
        )}
      >
        <div className="flex flex-none flex-col rounded-md border p-2 px-[10px]">
          <SingleLineEditorComponent
            id={`${generatedId}-title`}
            placeholder="Title Appointment"
            className="min-h-[10px] text-2xl"
            onSave={setTitle}
          />
        </div>

        <div className="mt-2 flex flex-none flex-row gap-2">
          <Label className="font-bold">Visible:</Label>
          <div className="flex flex-none flex-row gap-2">
            <RadioGroup
              defaultValue={visible}
              className="flex flex-row"
              onValueChange={(value) =>
                setVisible(value as IAppointmentVisible)
              }
            >
              {AppointmentVisibleList.map((item) => (
                <div className="flex items-center space-x-2">
                  <RadioGroupItem value={item.value} id={item.id} />

                  <Badge
                    className={cn(
                      item.value === 'public' ? 'bg-green-500' : 'bg-red-500',
                    )}
                  >
                    {item.name}
                  </Badge>
                </div>
              ))}
            </RadioGroup>
          </div>
        </div>

        <div className="flex-none">
          <Calendar
            mode="single"
            selected={date}
            onSelect={setDate}
            className="w-full"
          />
        </div>
      </div>
      <div className="flex-none">
        <div className="w-full flex-none">
          <Separator className="my-[10px]  w-full flex-none" />
          <div className="flex flex-none flex-row justify-end gap-2 p-[10px] pt-0">
            <Button
              type="button"
              size={'sm'}
              variant={'secondary'}
              onClick={() => onSubmitCreate()}
            >
              <Label>Create appointment</Label>
            </Button>
          </div>
        </div>
      </div>
    </>
  );
};
