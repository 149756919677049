import { useContext, useEffect, useState } from 'react';

import orderBy from 'lodash/orderBy';

import {
  PushEventType,
  AppointmentFragment,
} from '@/types/tanstack-query/generated';

import SubscriptionContext from '../subscriptions/SubscriptionContext';

const useWorkspaceAppointment = () => {
  const { pubsub } = useContext(SubscriptionContext);

  const [appointments, setAppointments] = useState<AppointmentFragment[]>([]);

  const storeAppointments = async (value: AppointmentFragment[]) => {
    setAppointments(orderBy(value, ['updated_at'], ['desc']));
  };

  const pushItem = async (props: { appointment: AppointmentFragment }) => {
    const clone = Object.assign([], appointments);
    clone.push(props.appointment);
    setAppointments(clone);
  };

  const updateItem = async (props: { appointment: AppointmentFragment }) => {
    const clone: AppointmentFragment[] = Object.assign([], appointments);
    setAppointments(
      clone.map((item) => {
        if (item.id === props.appointment.id) return { ...props.appointment };
        return { ...item };
      }),
    );
  };

  const popItem = async (props: { appointment_id: string }) => {
    const getAppointment = appointments.find(
      (item) => item.id === props.appointment_id,
    );
    const clone = {
      ...getAppointment,
      is_deleted: true,
    } as AppointmentFragment;
    updateItem({ appointment: clone });
  };

  useEffect(() => {
    if (pubsub && pubsub.data) {
      switch (pubsub.event) {
        case PushEventType.AppointmentAdded:
        case PushEventType.AppointmentUpdated:
          {
            const pubsubData = pubsub.data as AppointmentFragment;
            const find = appointments.find((item) => item.id === pubsubData.id);
            if (find) {
              updateItem({ appointment: pubsubData });
            } else {
              pushItem({ appointment: pubsubData });
            }
          }
          break;
        case PushEventType.AppointmentDeleted:
          popItem({ appointment_id: pubsub.id as string });
          break;
        default:
          break;
      }
    }
  }, [pubsub]);

  return {
    appointments: orderBy(
      appointments.filter((item) => !item.is_deleted),
      ['time_at'],
      ['asc'],
    ),
    event: {
      storeAppointments,
    },
  };
};

export default useWorkspaceAppointment;
