import { useContext, useEffect } from 'react';

import { yupResolver } from '@hookform/resolvers/yup';
import { useForm } from 'react-hook-form';
import slugify from 'slugify';
import * as yup from 'yup';

import { Avatar, AvatarFallback, AvatarImage } from '@/components/Avatar';
import { Button } from '@/components/Button';
import { Form, FormField } from '@/components/Form';
import { Input } from '@/components/Input';
import { Label } from '@/components/Label';
import { LabelError } from '@/components/LabelError';
import {
  Select,
  SelectContent,
  SelectGroup,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from '@/components/Select';
import { useErrorHandler } from '@/hooks/useToasterHandler';
import WorkspaceContext from '@/providers/workspace/WorkspaceContext';
import {
  RoleType,
  useGetWorkspaceQuery,
  useUpdateWorkspaceMutation,
  WorkspaceFragment,
} from '@/types/tanstack-query/generated';
import { timezones } from '@/utils/timezone';

import DeleteWorkspaceComponent from './components/DeletecWorkspaceComponent';
import HeaderSetting from '../components/HeaderSetting';
import LineSetting from '../components/LineSetting';

export type IUpdateWorkspace = {
  name: string;
  url?: string;
  logo?: string;
};

export const schemaUpdateWorkspace = yup.object().shape({
  name: yup.string().required(),
  url: yup.string(),
  logo: yup.string(),
});

const LogoSection = (props: { workspace: WorkspaceFragment }) => {
  return (
    <div className="flex flex-col gap-6">
      <Label className="text-md font-bold">Logo</Label>
      <Avatar className="h-[100px] w-[100px]">
        <AvatarImage src={props.workspace.logo ?? ''} />
        <AvatarFallback className="text-2xl uppercase">
          {props.workspace.name.slice(0, 2) ?? 'A'}
        </AvatarFallback>
      </Avatar>
      <Label className="opacity-50">
        Pick a logo for your workspace. Recommended size is 256x256px
      </Label>
    </div>
  );
};

const TimezoneSection = (props: {
  initValue?: string | undefined;
  onChange: (value: string) => void;
}) => {
  return (
    <div className="flex flex-col gap-2">
      <div className="flex flex-none flex-col gap-1">
        <Label className="grow">Timezone</Label>
        <Label className="text-xs opacity-50">
          Select or customize your timezone.
        </Label>
      </div>
      <div className="grow">
        <Select defaultValue={props.initValue} onValueChange={props.onChange}>
          <SelectTrigger>
            <SelectValue placeholder="Select a timezone" />
          </SelectTrigger>
          <SelectContent>
            <SelectGroup>
              {timezones.map((item) => (
                <SelectItem value={item.value}>{item.text}</SelectItem>
              ))}
            </SelectGroup>
          </SelectContent>
        </Select>
      </div>
    </div>
  );
};

const InformationSection = (props: {
  workspace: WorkspaceFragment;
  isDisabled?: boolean;
  onSubmit: (values: IUpdateWorkspace) => void;
}) => {
  const form = useForm<IUpdateWorkspace>({
    resolver: yupResolver<IUpdateWorkspace>(schemaUpdateWorkspace),
    defaultValues: {
      name: props.workspace.name,
      url: props.workspace.url,
    },
  });
  useEffect(() => {
    const slug = slugify(form.getValues('name') ?? '', {
      replacement: '_',
      lower: true,
      trim: true,
      locale: 'vi',
    });

    form.setValue('url', slug);
  }, [form.watch('name')]);

  return (
    <Form {...form}>
      <form onSubmit={form.handleSubmit(props.onSubmit)}>
        <div className="flex flex-col gap-6">
          <Label className="text-md font-bold">General</Label>
          <div className="flex flex-col gap-2">
            <Label>Workspace name</Label>
            <div className="max-w-[300px]">
              <FormField
                control={form.control}
                name="name"
                disabled={props.isDisabled}
                render={({ field }) => (
                  <>
                    <Input {...field} placeholder="Workspace name"></Input>

                    <LabelError>
                      {form.formState.errors.name?.message}
                    </LabelError>
                  </>
                )}
              />
            </div>
          </div>
          <div className="flex flex-col gap-2">
            <Label>Workspace URL</Label>
            <div className="max-w-[300px]">
              <FormField
                control={form.control}
                name="url"
                disabled={props.isDisabled}
                render={({ field }) => (
                  <>
                    <Input
                      {...field}
                      placeholder="Workspace URL"
                      readOnly
                    ></Input>

                    <LabelError>
                      {form.formState.errors.url?.message}
                    </LabelError>
                  </>
                )}
              />
            </div>
          </div>
          <div className="max-w-[100px]">
            <Button disabled={props.isDisabled} type="submit">
              Update
            </Button>
          </div>
        </div>
      </form>
    </Form>
  );
};

const SettingWorkspacePage = () => {
  const { selected, role } = useContext(WorkspaceContext);
  const { showErrorToast } = useErrorHandler();
  const getWorkspace = useGetWorkspaceQuery(
    { id: selected?.id ?? '' },
    { enabled: !!selected?.id },
  );

  const updateWorkspace = useUpdateWorkspaceMutation({
    onMutate: () => {},
    onError: (res) => {
      showErrorToast({ error: res });
    },
    onSuccess: () => {},
    onSettled: () => {},
  });

  return (
    <div>
      <HeaderSetting
        title={'Workspace'}
        subtitle={'Manage your workspace settings'}
      ></HeaderSetting>
      {getWorkspace.data?.workspace && (
        <div className="w-full">
          <LogoSection workspace={getWorkspace.data?.workspace} />
          <LineSetting />
          {/* <TimezoneSection
            initValue={getWorkspace.data?.workspace.timezone ?? ''}
            onChange={(value) =>
              updateWorkspace.mutate({
                updateInput: {
                  id: getWorkspace.data?.workspace.id ?? '',
                  timezone: value,
                },
              })
            }
          /> */}
          <LineSetting />
          <InformationSection
            workspace={getWorkspace.data?.workspace}
            isDisabled={updateWorkspace.isPending || role !== RoleType.Admin}
            onSubmit={(values) =>
              updateWorkspace.mutate({
                updateInput: {
                  id: getWorkspace.data?.workspace.id ?? '',
                  name: values.name,
                  url: values.url,
                },
              })
            }
          />
          <LineSetting />

          <DeleteWorkspaceComponent workspace={getWorkspace.data?.workspace} />
        </div>
      )}
    </div>
  );
};

export default SettingWorkspacePage;
