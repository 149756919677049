import {
  AlertDialogFooter,
  AlertDialog,
  AlertDialogDescription,
  AlertDialogTitle,
  AlertDialogHeader,
  AlertDialogContent,
} from '@/components/AlertDialog';
import { Button } from '@/components/Button';
import { useErrorHandler, useSuccessHandler } from '@/hooks/useToasterHandler';
import { MemoizedRenderDocument } from '@/renders/RenderDocument';
import { useRemoveDocumentMutation } from '@/types/tanstack-query/generated';
import CustomDateTime from '@/utils/format-datetime';

import DocumentDeleteContext from './DocumentDeleteContext';
import useDocumentDeleteProvider from './useDocumentDeleteProvider';

const DocumentDeleteProvider = ({
  children,
}: {
  children?: React.ReactNode;
}) => {
  const { showSuccessToast } = useSuccessHandler();
  const { showErrorToast } = useErrorHandler();
  const { isOpen, documentId, event } = useDocumentDeleteProvider();

  const removeDocument = useRemoveDocumentMutation({
    onMutate: () => {},
    onError: (res) => {
      showErrorToast({ error: res });
    },
    onSuccess: () => {
      event.setData({ isOpen: false });
      showSuccessToast({
        title: 'Document has been deleted',
        description: `${CustomDateTime.convertStringToCommon(
          new Date().toISOString(),
        )}`,
      });
    },
    onSettled: () => {},
  });

  return (
    <DocumentDeleteContext.Provider
      value={{
        isOpen: isOpen,
        documentId: documentId,
        showModal: (value) => event.setData(value),
      }}
    >
      {children}

      <AlertDialog
        open={isOpen}
        onOpenChange={(value) => event.setData({ isOpen: value })}
      >
        <AlertDialogContent>
          <AlertDialogHeader>
            <MemoizedRenderDocument
              id={documentId}
              renderItem={(document) =>
                document && (
                  <AlertDialogTitle>
                    Are you sure you want to delete "{document.title}" ?
                  </AlertDialogTitle>
                )
              }
            />

            <AlertDialogDescription>
              Deleted document are available in the "Recently deleted" view for
              30 days, before they are permanently deleted.
            </AlertDialogDescription>
          </AlertDialogHeader>
          <AlertDialogFooter>
            <Button
              disabled={removeDocument.isPending}
              variant={'secondary'}
              onClick={() => event.setData({ isOpen: false })}
            >
              Cancel
            </Button>
            <Button
              disabled={removeDocument.isPending}
              variant={'destructive'}
              onClick={() =>
                documentId &&
                removeDocument.mutate({
                  id: documentId,
                })
              }
            >
              Delete
            </Button>
          </AlertDialogFooter>
        </AlertDialogContent>
      </AlertDialog>
    </DocumentDeleteContext.Provider>
  );
};
export default DocumentDeleteProvider;
