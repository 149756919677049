/* eslint-disable import/no-extraneous-dependencies */
import { usePlateEditor } from '@udecode/plate/react';
import { SingleLinePlugin } from '@udecode/plate-break/react';

import { withPlaceholders } from '@/components/plate-ui/placeholder';

const useSingleLineEditor = (id: string) => {
  return usePlateEditor({
    id: id ?? '',
    plugins: [SingleLinePlugin],
    override: {
      components: withPlaceholders({}),
    },
  });
};
export default useSingleLineEditor;
