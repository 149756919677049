import React, { useContext } from 'react';

import WorkspaceContext from '@/providers/workspace/WorkspaceContext';
import {
  FavoriteType,
  FavoriteFragment,
} from '@/types/tanstack-query/generated';

const RenderFavorite = (props: {
  id?: string | undefined;
  map_id?: string | undefined;
  type: FavoriteType;
  renderItem: (value: FavoriteFragment | undefined) => React.ReactNode;
}) => {
  const { favorites } = useContext(WorkspaceContext);

  if (props.id) {
    return (
      <>{props.renderItem(favorites.find((item) => item.id === props.id))}</>
    );
  }

  if (props.map_id) {
    return (
      <>
        {props.renderItem(
          favorites.find(
            (item) => item.map_id === props.map_id && item.type === props.type,
          ),
        )}
      </>
    );
  }

  return <>{props.renderItem(undefined)}</>;
};
const MemoizedRenderFavorite = React.memo(RenderFavorite);
export { MemoizedRenderFavorite };
