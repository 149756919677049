import { AppointmentStatus } from '../types/tanstack-query/generated';

class CustomAppointment {
  static getColorByStatus(status: AppointmentStatus) {
    switch (status) {
      case AppointmentStatus.Processing:
        return '#4c90ff ';
      case AppointmentStatus.Completed:
        return '#64f216';
      case AppointmentStatus.Cancelled:
        return '#ef4444';
      default:
        return 'bg-primary';
    }
  }
}

export default CustomAppointment;
