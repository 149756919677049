import { create } from 'zustand';

type IPageState = {
  isOpen: boolean;
  appointmentId: string | undefined;
  showPopup: (props: { isOpen: boolean; appointmentId?: string }) => void;
};

const createStore = create<IPageState>((set) => ({
  isOpen: false,
  appointmentId: undefined,
  showPopup: (props: { isOpen: boolean; appointmentId?: string }) =>
    set({ isOpen: props.isOpen, appointmentId: props.appointmentId }),
}));

const useAppointmentDetailProvider = () => {
  const { isOpen, appointmentId, showPopup } = createStore();
  return {
    isOpen,
    appointmentId,
    showPopup,
  };
};

export default useAppointmentDetailProvider;
