/* eslint-disable import/no-extraneous-dependencies */
import React, { useContext, useEffect, useState } from 'react';

import classNames from 'classnames';

import { Button } from '@/components/Button';
import EditorPlateComponent from '@/components/Editor/FullEditor/EditorPlateComponent';
import SingleLineEditorComponent from '@/components/Editor/SingleLineEditor/SingleLineEditorComponent';
import { Label } from '@/components/Label';
import { Separator } from '@/components/Separator';
import {
  useErrorHandler,
  useSuccessHandler,
  useWarningHandler,
} from '@/hooks/useToasterHandler';
import EmojiIcon, { IEmojiIcon } from '@/icons/emoji';
import TeamButton from '@/pages/issue/components/Properties/TeamButton';
import DocumentServicesContext from '@/providers/document/DocumentServicesContext';
import WorkspaceContext from '@/providers/workspace/WorkspaceContext';
import { useCreateDocumentMutation } from '@/types/tanstack-query/generated';
import { ICreateIssueType } from '@/types/tanstack-query/issue/issue.interface';
import { generateUuid } from '@/utils/generate';

export const CreateDocumentComponent = (props: {
  initTeamId?: string;
  initDocumentId?: string;
  type: ICreateIssueType;
  onSuccess: () => void;
  onCancel: () => void;
}) => {
  const { teams, selected: selectedWorkspace } = useContext(WorkspaceContext);
  const { showErrorToast } = useErrorHandler();
  const { showSuccessToast } = useSuccessHandler();
  const { showWarningToast } = useWarningHandler();
  const { isShowCreateDocument, showCreateDocumentPopup } = useContext(
    DocumentServicesContext,
  );
  const [generatedId, setGeneratedId] = useState<string>(generateUuid());

  const [selectedTeamId, setSelectedTeamId] = useState<string>();
  const [title, setTitle] = useState<string>();
  const [description, setDescription] = useState<string>();

  const createDocument = useCreateDocumentMutation({
    onMutate: () => {},
    onError: (res) => {
      showErrorToast({ error: res });
    },
    onSuccess: () => {
      setGeneratedId(generateUuid());
      showCreateDocumentPopup({ isOpen: false });
      props.onSuccess();
      showSuccessToast({
        title: 'Document has been created',
      });
    },
    onSettled: () => {},
  });

  const onSubmitCreate = () => {
    if (selectedTeamId && selectedWorkspace) {
      if (!title) {
        showWarningToast({
          title: 'Title document is required',
          description: 'Please add a title before submitting.',
        });
        return;
      }

      createDocument.mutate({
        createInput: {
          title: title,
          description: description,
          workspace_id: selectedWorkspace?.id,
          document_id: props.initDocumentId,
          team_id: selectedTeamId,
        },
      });
    }
  };

  const onReset = () => {
    setGeneratedId(generateUuid());
  };

  const onInitForm = () => {};

  useEffect(() => {
    if (!isShowCreateDocument) {
      onReset();
    } else {
      onInitForm();
    }
  }, [isShowCreateDocument]);

  useEffect(() => {
    if (teams.length > 0 && !props.initTeamId) {
      setSelectedTeamId(teams[0]?.id);
    } else {
      setSelectedTeamId(props.initTeamId);
    }
  }, [props]);

  return (
    <>
      <div className="flex-none">
        <div className="flex items-center gap-2 p-[10px]">
          <TeamButton
            selected={selectedTeamId}
            onChange={setSelectedTeamId}
            renderItem={(value) => (
              <Button
                size={'sm'}
                className="h-[26px] max-w-[80px]"
                type="button"
              >
                <div className="flex flex-row items-center gap-2">
                  <EmojiIcon
                    tag={value?.icon as IEmojiIcon}
                    attrs={{
                      width: 16,
                      height: 16,
                      color: value?.color,
                    }}
                  />
                  <Label>{value?.identifier}</Label>
                </div>
              </Button>
            )}
          />
          <Label>›</Label>

          <Label>
            {props.type === 'modal' ? 'New document' : 'New sub-document'}
          </Label>
        </div>
      </div>
      <div className="flex flex-none flex-col px-[10px]">
        <SingleLineEditorComponent
          id={`${generatedId}-title`}
          placeholder="Title Document"
          className="min-h-[10px] text-2xl"
          onSave={setTitle}
        />
      </div>
      <div
        className={classNames(
          'flex w-full grow flex-col overflow-auto px-[10px]',
          {
            'min-h-[200px]': props.type === 'modal',
            'min-h-[100px]': props.type !== 'modal',
          },
        )}
      >
        <EditorPlateComponent
          id={`${generatedId}-description`}
          type="document"
          workspace_id={selectedWorkspace?.id ?? ''}
          placeholder="Add description ..."
          onSave={setDescription}
          className="min-h-[200px]"
        />
      </div>
      <div className="flex-none">
        <div className="w-full flex-none">
          <Separator className="my-[10px]  w-full flex-none" />
          <div className="flex flex-none flex-row justify-end gap-2 p-[10px] pt-0">
            {props.type === 'component' && (
              <Button
                disabled={createDocument.isPending}
                type="button"
                size={'sm'}
                variant={'ghost'}
                onClick={() => props.onCancel()}
              >
                <Label>Cancel</Label>
              </Button>
            )}

            <Button
              type="button"
              size={'sm'}
              variant={'secondary'}
              onClick={() => onSubmitCreate()}
            >
              <Label>
                {props.type === 'modal' ? 'Create document' : 'Save'}
              </Label>
            </Button>
          </div>
        </div>
      </div>
    </>
  );
};
