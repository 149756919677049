import { useState } from 'react';

import { cn } from '@udecode/cn';

import {
  AlertDialog,
  AlertDialogContent,
  AlertDialogDescription,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogTitle,
} from '@/components/AlertDialog';
import { Badge } from '@/components/Badge';
import { Button } from '@/components/Button';
import { Label } from '@/components/Label';
import { RadioGroup, RadioGroupItem } from '@/components/RadioGroup';
import { useErrorHandler, useSuccessHandler } from '@/hooks/useToasterHandler';
import IssueTableList from '@/pages/issue/components/IssueTableList/IssueTableList';
import { MemoizedRenderAppointment } from '@/renders/RenderAppointment';
import { MemoizedRenderIssue } from '@/renders/RenderIssue';
import {
  IAppointmentVisible,
  AppointmentVisibleList,
  AppointmentStatusList,
} from '@/types/tanstack-query/appointment/appointment.interface';
import {
  AppointmentStatus,
  useRemoveAppointmentMutation,
  useUpdateAppointmentMutation,
} from '@/types/tanstack-query/generated';
import CustomDateTime from '@/utils/format-datetime';

import AppointmentDetailContext from './AppointmentDetailContext';
import useAppointmentDetailProvider from './useAppointmentDetailProvider';

const AppointmentDetailProvider = ({
  children,
}: {
  children?: React.ReactNode;
}) => {
  const { showSuccessToast } = useSuccessHandler();
  const { showErrorToast } = useErrorHandler();
  const { isOpen, appointmentId, showPopup } = useAppointmentDetailProvider();
  const [visible, setVisible] = useState<IAppointmentVisible>();
  const [status, setStatus] = useState<AppointmentStatus>();

  const removeAppointment = useRemoveAppointmentMutation({
    onMutate: () => {},
    onError: (res) => {
      showErrorToast({ error: res });
    },
    onSuccess: () => {
      showPopup({ isOpen: false, appointmentId: undefined });
      showSuccessToast({
        title: 'Appointment has been deleted',
        description: `${CustomDateTime.convertStringToCommon(
          new Date().toISOString(),
        )}`,
      });
    },
    onSettled: () => {},
  });

  const updateAppointment = useUpdateAppointmentMutation({
    onMutate: () => {},
    onError: (res) => {
      showErrorToast({ error: res });
    },
    onSuccess: () => {
      showPopup({ isOpen: false, appointmentId: undefined });
    },
    onSettled: () => {},
  });

  return (
    <AppointmentDetailContext.Provider
      value={{
        isOpen: isOpen,
        appointmentId: appointmentId,
        showPopup: (value) => showPopup(value),
      }}
    >
      {children}

      <AlertDialog
        open={isOpen}
        onOpenChange={(value) => showPopup({ isOpen: value })}
      >
        <MemoizedRenderAppointment
          id={appointmentId}
          renderItem={(appointment) => (
            <>
              <AlertDialogContent className="max-w-2xl">
                <AlertDialogHeader>
                  <AlertDialogTitle>
                    Appointment at{' '}
                    {CustomDateTime.convertStringToCommon(
                      appointment?.time_at ?? '',
                    )}
                  </AlertDialogTitle>
                  <AlertDialogDescription>
                    <div className="flex flex-col gap-4">
                      <div>{appointment?.description}</div>
                      {appointment?.issue_id && (
                        <MemoizedRenderIssue
                          id={appointment?.issue_id}
                          renderItem={(issue) =>
                            issue && (
                              <IssueTableList
                                data={[issue]}
                                type={'issues'}
                                onNavigate={() => showPopup({ isOpen: false })}
                              />
                            )
                          }
                        />
                      )}

                      <div className="mt-2 flex flex-none flex-row gap-2">
                        <Label className="font-bold">Visible:</Label>
                        <div className="flex flex-none flex-row gap-2">
                          <RadioGroup
                            defaultValue={
                              appointment?.is_public ? 'public' : 'private'
                            }
                            className="flex flex-row"
                            onValueChange={(value) =>
                              setVisible(value as IAppointmentVisible)
                            }
                          >
                            {AppointmentVisibleList.map((item) => (
                              <div className="flex items-center space-x-2">
                                <RadioGroupItem
                                  value={item.value}
                                  id={item.id}
                                />

                                <Badge
                                  className={cn(
                                    item.value === 'public'
                                      ? 'bg-green-500'
                                      : 'bg-red-500',
                                  )}
                                >
                                  {item.name}
                                </Badge>
                              </div>
                            ))}
                          </RadioGroup>
                        </div>
                      </div>
                      <div className="mt-2 flex flex-none flex-row gap-2">
                        <Label className="font-bold">Status:</Label>
                        <div className="flex flex-none flex-row gap-2">
                          <RadioGroup
                            defaultValue={appointment?.status}
                            className="flex flex-row"
                            onValueChange={(value) =>
                              setStatus(value as AppointmentStatus)
                            }
                          >
                            {AppointmentStatusList.map((item) => (
                              <div className="flex items-center space-x-2">
                                <RadioGroupItem
                                  value={item.value}
                                  id={item.id}
                                />

                                <Label className="font-bold">{item.name}</Label>
                              </div>
                            ))}
                          </RadioGroup>
                        </div>
                      </div>
                    </div>
                  </AlertDialogDescription>
                </AlertDialogHeader>
                <AlertDialogFooter className="mt-2">
                  <Button
                    disabled={removeAppointment.isPending}
                    variant={'secondary'}
                    onClick={() => showPopup({ isOpen: false })}
                  >
                    Close
                  </Button>
                  <Button
                    disabled={removeAppointment.isPending}
                    variant={'destructive'}
                    onClick={() =>
                      appointment &&
                      removeAppointment.mutate({
                        id: appointment.id,
                      })
                    }
                  >
                    Delete
                  </Button>
                  <Button
                    disabled={updateAppointment.isPending}
                    variant={'default'}
                    onClick={() => {
                      updateAppointment.mutate(
                        {
                          updateInput: {
                            id: appointment?.id ?? '',
                            status: status,
                            is_public: visible === 'public',
                          },
                        },
                        {
                          onSuccess: () => {
                            showSuccessToast({
                              title: 'Appointment has been updated',
                              description: `${CustomDateTime.convertStringToCommon(
                                new Date().toISOString(),
                              )}`,
                            });
                          },
                        },
                      );
                    }}
                  >
                    Save
                  </Button>
                </AlertDialogFooter>
              </AlertDialogContent>
            </>
          )}
        />
      </AlertDialog>
    </AppointmentDetailContext.Provider>
  );
};
export default AppointmentDetailProvider;
