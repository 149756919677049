import { useContext } from 'react';

import { Calendar, Globe, GlobeLock } from 'lucide-react';

import { Badge } from '@/components/Badge';
import { Label } from '@/components/Label';
import useAppointmentDetailProvider from '@/providers/appointment/AppointmentDetail/useAppointmentDetailProvider';
import WorkspaceContext from '@/providers/workspace/WorkspaceContext';
import {
  AppointmentFragment,
  AppointmentStatus,
} from '@/types/tanstack-query/generated';
import CustomDateTime from '@/utils/format-datetime';

const AppointmentRowItem = (props: {
  appointment: AppointmentFragment;
  onClick: (item: AppointmentFragment) => void;
}) => {
  return (
    <div
      className="border-bottom flex h-[50px] w-full cursor-pointer items-center gap-2 overflow-hidden border-[1px] border-secondary hover:bg-secondary"
      onClick={() => props.onClick(props.appointment)}
    >
      <div className="flex w-[110px] flex-none flex-row items-center justify-start gap-1 px-2">
        <div className="flex-none items-center justify-start">
          <Calendar size={18} />
        </div>

        <Label className="grow">{`${CustomDateTime.convertStringToDateTime(
          props.appointment.time_at,
          'dd/MM/yyyy',
        )}`}</Label>
      </div>
      <div className="flex grow flex-row items-center justify-start gap-2 px-2">
        <div className="flex flex-col gap-1">
          {props.appointment.is_public ? (
            <Globe size={18} className="text-primary" />
          ) : (
            <GlobeLock size={18} className="text-primary" />
          )}
        </div>
        <Label>{`${props.appointment.description}`}</Label>
      </div>

      <div className="w-[100px] flex-none items-center justify-start px-2">
        {props.appointment.status === AppointmentStatus.Completed && (
          <Badge className="bg-success capitalize text-white">Completed</Badge>
        )}
        {props.appointment.status === AppointmentStatus.Processing && (
          <Badge className="bg-primary capitalize text-white">Processing</Badge>
        )}
        {props.appointment.status === AppointmentStatus.Cancelled && (
          <Badge className="bg-destructive capitalize text-white">
            Cancelled
          </Badge>
        )}
      </div>
    </div>
  );
};

const AppointmentTableList = (props: { data: AppointmentFragment[] }) => {
  const { selected } = useContext(WorkspaceContext);
  const { showPopup } = useAppointmentDetailProvider();

  return (
    <div className="w-full">
      {selected &&
        props.data.map(
          (item) =>
            item && (
              <AppointmentRowItem
                key={item.id}
                appointment={item}
                onClick={() =>
                  showPopup({ isOpen: true, appointmentId: item.id })
                }
              />
            ),
        )}
    </div>
  );
};
export default AppointmentTableList;
