/* eslint-disable import/no-extraneous-dependencies */

import { useContext } from 'react';

import dayGridPlugin from '@fullcalendar/daygrid';
import interactionPlugin from '@fullcalendar/interaction';
import FullCalendar from '@fullcalendar/react';

import AppointmentDetailContext from '@/providers/appointment/AppointmentDetail/AppointmentDetailContext';
import AppointmentServicesContext from '@/providers/appointment/AppointmentServicesContext';
import WorkspaceContext from '@/providers/workspace/WorkspaceContext';
import CustomAppointment from '@/utils/custom-appointment';
import CustomDateTime from '@/utils/format-datetime';

import MyAppointmentHeader from '../components/MyAppointmentHeader/MyAppointmentHeader';

const AppointmentCalendarViewPage = () => {
  const { appointments } = useContext(WorkspaceContext);
  const { showPopup } = useContext(AppointmentDetailContext);
  const { showCreateAppointmentPopup } = useContext(AppointmentServicesContext);
  return (
    <div className="flex h-full w-full flex-col overflow-hidden">
      <MyAppointmentHeader active={'calendar'} />
      <div className="flex w-full grow flex-col overflow-hidden p-2">
        <FullCalendar
          height={`100%`}
          plugins={[dayGridPlugin, interactionPlugin]}
          initialView="dayGridMonth"
          titleFormat={{ year: 'numeric', month: 'long', day: 'numeric' }}
          headerToolbar={{
            start: 'prev',
            center: 'title',
            end: 'next',
          }}
          selectable={true}
          dateClick={(info) => {
            showCreateAppointmentPopup({ isOpen: true, initDate: info.date });
          }}
          events={appointments?.map((appointment) => ({
            id: appointment.id,
            color: CustomAppointment.getColorByStatus(appointment.status),
            textColor: 'black',
            title: appointment.description ?? '',
            date: CustomDateTime.convertStringToDateTime(
              appointment.time_at,
              'yyyy-MM-dd',
            ),
          }))}
          eventClick={(info) =>
            showPopup({ isOpen: true, appointmentId: info.event.id })
          }
        />
      </div>
    </div>
  );
};

export default AppointmentCalendarViewPage;
