import { createContext } from 'react';

type TAppointmentDetailContext = {
  isOpen: boolean;
  appointmentId: string | undefined;
  showPopup: (props: { isOpen: boolean; appointmentId?: string }) => void;
};

const AppointmentDetailContext = createContext<TAppointmentDetailContext>({
  isOpen: false,
  appointmentId: undefined,
  showPopup: () => undefined,
});

export default AppointmentDetailContext;
