import { useContext } from 'react';

import { Button } from '@/components/Button';
import { TooltipRoot } from '@/components/Tooltip';
import { UserAvatar } from '@/components/UserAvatar';
import { useErrorHandler } from '@/hooks/useToasterHandler';
import WorkspaceContext from '@/providers/workspace/WorkspaceContext';
import { MemoizedRenderMember } from '@/renders/RenderMember';
import { MemoizedRenderSubscriber } from '@/renders/RenderSubscriber';
import {
  SubscriberType,
  useSubscribeMutation,
} from '@/types/tanstack-query/generated';

const SubscribersComponent = (props: {
  issue_id: string;
  workspace_id: string;
}) => {
  const { showErrorToast } = useErrorHandler();
  const { subscribers } = useContext(WorkspaceContext);
  const filter = subscribers.filter(
    (item) =>
      item.map_id === props.issue_id && item.type === SubscriberType.Issue,
  );

  const subscribe = useSubscribeMutation({
    onMutate: () => {},
    onError: (res) => {
      showErrorToast({ error: res });
    },
    onSuccess: () => {},
    onSettled: () => {},
  });

  return (
    <div className="flex w-full flex-row items-center justify-end gap-6">
      <MemoizedRenderSubscriber
        map_id={props.issue_id}
        type={SubscriberType.Issue}
        renderItem={(subscriber) => (
          <Button
            size={'sm'}
            type="button"
            variant={'outline'}
            className="max-w-[100px]"
            onClick={() =>
              subscribe.mutate({
                input: {
                  map_id: props.issue_id,
                  workspace_id: props.workspace_id,
                  type: SubscriberType.Issue,
                },
              })
            }
          >
            {subscriber ? 'Unsubscribe' : 'Subscribe'}
          </Button>
        )}
      />

      <div className="relative flex flex-row items-center justify-end">
        {filter.map(
          (item, index) =>
            item.member_id && (
              <MemoizedRenderMember
                key={item.id}
                id={item.member_id}
                renderItem={(member) => (
                  <TooltipRoot key={member?.id} content={member?.username}>
                    <UserAvatar
                      style={{ marginLeft: index === 0 ? 0 : -10 }}
                      size="sm"
                      url={member?.image}
                      fallback={member?.username}
                    />
                  </TooltipRoot>
                )}
              />
            ),
        )}
      </div>
    </div>
  );
};

export default SubscribersComponent;
