/* eslint-disable import/no-extraneous-dependencies */
import { useContext, useEffect, useState } from 'react';

import { InfoIcon, MoreHorizontal } from 'lucide-react';
import { useNavigate, useParams } from 'react-router-dom';

import { AnimationFadeIn } from '@/components/Animations';
import { BadgeDraft } from '@/components/BadgeDraft';
import { Button } from '@/components/Button';
import { Card } from '@/components/Card';
import EditorPlateComponent from '@/components/Editor/FullEditor/EditorPlateComponent';
import SingleLineEditorComponent from '@/components/Editor/SingleLineEditor/SingleLineEditorComponent';
import { Label } from '@/components/Label';
import { ScrollArea, ScrollBar } from '@/components/ScrollArea';
import { Separator } from '@/components/Separator';
import { useErrorHandler } from '@/hooks/useToasterHandler';
import { FavoriteFilledIcon, FavoriteIcon, PlusIcon } from '@/icons/core';
import WorkspaceContext from '@/providers/workspace/WorkspaceContext';
import {
  MemoizedRenderDocument,
  MemoizedRenderDocumentsByParentId,
} from '@/renders/RenderDocument';
import { MemoizedRenderFavorite } from '@/renders/RenderFavorite';
import {
  FavoriteType,
  IDocumentFragment,
  TeamFragment,
  useToggleFavoriteMutation,
  useUpdateDocumentMutation,
} from '@/types/tanstack-query/generated';

import DocumentAttachmentsComponent from './Attachments/DocumentAttachmentsComponent';
import { ToolsMenuDocument } from './ContextMenuDocument';
import { CreateSubDocumentDialog } from './CreateDocument/CreateSubDocumentDialog';
import HeaderDocumentComponent from './HeaderDocumentComponent';
import SubDocumentsComponent from './SubDocuments/SubDocumentsComponent';

const DocumentNotFound = () => {
  const params = useParams();
  return (
    <div className="absolute left-1/2 top-1/2 -translate-x-1/2 -translate-y-1/2 text-center">
      <div className="flex flex-col gap-2">
        <h2 className="flex items-center justify-center gap-2 font-semibold">
          <InfoIcon />
          Document Not Found
        </h2>
        <p>There is no issue with the identifier {params.id}</p>
      </div>
    </div>
  );
};

const ParentIssue = (props: { document: IDocumentFragment }) => {
  const navigate = useNavigate();
  const { selected } = useContext(WorkspaceContext);
  return (
    <div className="flex w-full flex-row items-center gap-1">
      <Label className="flex-none text-xs opacity-50">Sub-document of</Label>
      <div
        className="flex cursor-pointer flex-row items-center gap-2  rounded-md p-1 hover:bg-secondary"
        onClick={() =>
          navigate(`/${selected?.url}/document/${props.document.id}`)
        }
      >
        <div className="flex flex-row items-center gap-2">
          <Label className="text-xs">{props.document.title}</Label>
        </div>
      </div>
    </div>
  );
};

const DocumentDetailComponent = (props: {
  document: IDocumentFragment;
  isHideHeader?: boolean;
}) => {
  const { teams } = useContext(WorkspaceContext);

  const { showErrorToast } = useErrorHandler();

  const updateDocument = useUpdateDocumentMutation({
    onMutate: () => {},
    onError: (res) => {
      showErrorToast({ error: res });
    },
    onSuccess: () => {},
    onSettled: () => {},
  });
  const [isOpenCreateSubDocument, setIsOpenCreateSubDocument] =
    useState<Boolean>(false);
  const [isDraft, setIsDraft] = useState<boolean>(true);

  const [team, setTeam] = useState<TeamFragment>();
  const toggleFavorite = useToggleFavoriteMutation({
    onMutate: () => {},
    onError: (res) => {
      showErrorToast({ error: res });
    },
    onSuccess: () => {},
    onSettled: () => {},
  });
  useEffect(() => {
    setTeam(teams.find((item) => item.id === props.document.team_id));
  }, [teams]);

  return (
    <>
      {team && !props.isHideHeader && (
        <>
          <HeaderDocumentComponent data={props.document} team={team} />
          <Separator />
        </>
      )}
      <div className="relative z-10 flex h-full grow md:pt-0">
        <ScrollArea className="w-full" type="auto">
          <div className="m-auto w-full max-w-[900px] items-start px-[24px] pb-[70px]">
            <div className="flex w-full flex-col">
              <div className="mb-[10px] mt-10 flex ">
                <SingleLineEditorComponent
                  className="min-h-[10px] text-2xl"
                  id={`${props.document.id}-title`}
                  content={props.document.title}
                  disabled={props.document.is_deleted}
                  placeholder={'Document title'}
                  onSave={(content) => {
                    updateDocument.mutate({
                      updateInput: {
                        id: props.document.id,
                        title: content ?? undefined,
                      },
                    });
                  }}
                />
                <MemoizedRenderFavorite
                  map_id={props.document.id}
                  type={FavoriteType.Document}
                  renderItem={(value) => (
                    <div className="flex w-[50px] cursor-pointer items-center justify-center">
                      {!value ? (
                        <FavoriteIcon
                          width={18}
                          height={18}
                          onClick={() =>
                            toggleFavorite.mutate({
                              toggleFavorite: {
                                map_id: props.document.id,
                                type: FavoriteType.Document,
                                workspace_id: props.document.workspace_id,
                              },
                            })
                          }
                        />
                      ) : (
                        <FavoriteFilledIcon
                          width={18}
                          height={18}
                          style={{ color: '#ff9000' }}
                          onClick={() =>
                            toggleFavorite.mutate({
                              toggleFavorite: {
                                map_id: props.document.id,
                                type: FavoriteType.Document,
                                workspace_id: props.document.workspace_id,
                              },
                            })
                          }
                        />
                      )}
                    </div>
                  )}
                />
                <ToolsMenuDocument
                  contextMenu={false}
                  document={props.document}
                  workspace_id={props.document.workspace_id}
                >
                  <Button size={'sm'} variant={'ghost'}>
                    <MoreHorizontal />
                  </Button>
                </ToolsMenuDocument>
              </div>

              {props.document.document_id && (
                <MemoizedRenderDocument
                  id={props.document.document_id}
                  renderItem={(parent) =>
                    parent && <ParentIssue document={parent} />
                  }
                />
              )}

              <div className="my-[20px] flex w-full">
                <EditorPlateComponent
                  id={`${props.document.id}-description`}
                  type="document"
                  content={props.document.description ?? ''}
                  workspace_id={props.document.workspace_id}
                  disabled={props.document.is_deleted}
                  placeholder="Add description ..."
                  className="min-h-[200px]"
                  onSave={(value) => {
                    if (value === props.document.description) return;
                    updateDocument.mutate({
                      updateInput: {
                        id: props.document.id,
                        description: value,
                      },
                    });
                  }}
                  onDraft={setIsDraft}
                />
              </div>
              <div className="lbl-md my-[20px] flex w-full text-primary">
                <DocumentAttachmentsComponent
                  disabled={props.document.is_deleted}
                  document_id={props.document.id}
                  workspace_id={props.document.workspace_id}
                />
              </div>
              <div className="flex w-full">
                <Button
                  type="button"
                  size={'sm'}
                  disabled={props.document.is_deleted}
                  variant={'ghost'}
                  onClick={() => setIsOpenCreateSubDocument(true)}
                >
                  <div className="flex flex-row items-center gap-2">
                    <PlusIcon width={14} height={14} />
                    <Label className="text-xs">Add sub-documents</Label>
                  </div>
                </Button>
              </div>
              <MemoizedRenderDocumentsByParentId
                id={props.document.id}
                renderItem={(children) =>
                  children.length > 0 && (
                    <SubDocumentsComponent data={children} />
                  )
                }
              />
              {isOpenCreateSubDocument && (
                <AnimationFadeIn>
                  <Card className="mt-[10px] p-0">
                    <CreateSubDocumentDialog
                      initDocumentId={props.document.id}
                      initTeamId={props.document.team_id}
                      onSuccess={() => setIsOpenCreateSubDocument(false)}
                      onCancel={() => setIsOpenCreateSubDocument(false)}
                    />
                  </Card>
                </AnimationFadeIn>
              )}
            </div>
          </div>
          <ScrollBar orientation="vertical" />
        </ScrollArea>
      </div>
      {isDraft && <BadgeDraft />}
    </>
  );
};

const DocumentDetailPage = (props?: {
  id?: string;
  isHideHeader?: boolean;
}) => {
  const params = useParams();

  return (
    <div className="relative flex w-full flex-col md:h-full md:overflow-hidden">
      <MemoizedRenderDocument
        id={props?.id ?? params.id}
        renderItem={(document) => {
          return (
            <>
              {document ? (
                <DocumentDetailComponent
                  document={document}
                  isHideHeader={props?.isHideHeader}
                />
              ) : (
                <DocumentNotFound />
              )}
            </>
          );
        }}
      />
    </div>
  );
};

export default DocumentDetailPage;
