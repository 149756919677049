/* eslint-disable import/no-extraneous-dependencies */
import { useContext, useEffect, useState } from 'react';

import classNames from 'classnames';

import { Button } from '@/components/Button';
import EditorPlateComponent from '@/components/Editor/FullEditor/EditorPlateComponent';
import SingleLineEditorComponent from '@/components/Editor/SingleLineEditor/SingleLineEditorComponent';
import { Label } from '@/components/Label';
import { Separator } from '@/components/Separator';
import {
  useErrorHandler,
  useSuccessHandler,
  useWarningHandler,
} from '@/hooks/useToasterHandler';
import EmojiIcon, { IEmojiIcon } from '@/icons/emoji';
import TeamButton from '@/pages/issue/components/Properties/TeamButton';
import ProjectServicesContext from '@/providers/project/ProjectServicesContext';
import WorkspaceContext from '@/providers/workspace/WorkspaceContext';
import { BasePriorities } from '@/services/issue/issue-utils';
import {
  CreateProjectInput,
  PriorityType,
  useCreateProjectMutation,
} from '@/types/tanstack-query/generated';
import { generateUuid } from '@/utils/generate';

import ProjectPropertiesComponent from '../ProjectPropertiesComponent';

export const CreateProjectComponent = (props: {
  initTeamId?: string;
  onSuccess: () => void;
  onCancel: () => void;
}) => {
  const { teams, selected: selectedWorkspace } = useContext(WorkspaceContext);
  const { showErrorToast } = useErrorHandler();
  const { showSuccessToast } = useSuccessHandler();
  const { showWarningToast } = useWarningHandler();

  const { isShowCreateProject, showCreateProjectPopup } = useContext(
    ProjectServicesContext,
  );
  const [generatedId, setGeneratedId] = useState<string>(generateUuid());

  const [title, setTitle] = useState<string>();
  const [shortSummary, setShortSummary] = useState<string>();
  const [description, setDescription] = useState<string>();

  const [selectedProjectStatusId, setSelectedProjectStatusId] =
    useState<string>();
  const [selectedTeamId, setSelectedTeamId] = useState<string | undefined>();
  const [selectedLeadId, onSelectedLeadId] = useState<string | undefined>();
  const [selectedMemberIds, setSelectedMemberIds] = useState<string[]>([]);
  const [selectedPriority, setSelectedPriority] = useState<
    PriorityType | undefined
  >();
  const [selectedLabelIds, setSelectedLabelIds] = useState<string[]>([]);
  const [selectedStartAt, setSelectedStartAt] = useState<Date | undefined>();
  const [selectedEndAt, setSelectedEndAt] = useState<Date | undefined>();

  const createProject = useCreateProjectMutation({
    onMutate: () => {},
    onError: (res) => {
      showErrorToast({ error: res });
    },
    onSuccess: () => {
      setGeneratedId(generateUuid());
      showCreateProjectPopup({ isOpen: false });
      props.onSuccess();
      showSuccessToast({
        title: 'Project has been created',
      });
    },
    onSettled: () => {},
  });

  const onSubmitCreate = () => {
    if (selectedTeamId && selectedWorkspace) {
      if (!title) {
        showWarningToast({
          title: 'Title project is required',
          description: 'Please add a title before submitting.',
        });
        return;
      }
      const createProjectInput: CreateProjectInput = {
        title: title,
        description: description,
        workspace_id: selectedWorkspace?.id,
        icon: '',
        priority: selectedPriority,
        short_summary: shortSummary,
        project_status_id: selectedProjectStatusId,
        label_ids: selectedLabelIds.join(','),
        leader_id: selectedLeadId,
        member_ids: selectedMemberIds.join(','),
        team_ids: [selectedTeamId].join(','),
        start_at: selectedStartAt?.toISOString(),
        end_at: selectedEndAt?.toISOString(),
      };
      createProject.mutate({
        createInput: createProjectInput,
      });
    }
  };

  const onReset = () => {
    setGeneratedId(generateUuid());
    setSelectedProjectStatusId(undefined);
    setSelectedPriority(BasePriorities[0]?.type);
  };

  const onInitForm = () => {};

  useEffect(() => {
    if (!isShowCreateProject) {
      onReset();
    } else {
      onInitForm();
    }
  }, [isShowCreateProject]);

  useEffect(() => {
    onReset();
    onInitForm();
  }, [selectedTeamId]);

  useEffect(() => {
    if (teams.length > 0 && !props.initTeamId) {
      setSelectedTeamId(teams[0]?.id);
    } else {
      setSelectedTeamId(props.initTeamId);
    }
  }, [props]);

  return (
    <>
      <div className="flex-none">
        <div className="flex items-center gap-2 p-[10px]">
          <TeamButton
            selected={selectedTeamId}
            onChange={setSelectedTeamId}
            renderItem={(value) => (
              <Button
                size={'sm'}
                className="h-[26px] max-w-[80px]"
                type="button"
              >
                <div className="flex flex-row items-center gap-2">
                  <EmojiIcon
                    tag={value?.icon as IEmojiIcon}
                    attrs={{
                      width: 16,
                      height: 16,
                      color: value?.color,
                    }}
                  />
                  <Label>{value?.identifier}</Label>
                </div>
              </Button>
            )}
          />
          <Label>›</Label>

          <Label>New project</Label>
        </div>
      </div>
      <div className="flex flex-none flex-col px-[10px]">
        <div className="mb-[10px] flex ">
          <SingleLineEditorComponent
            id={`${generatedId}-title`}
            className="min-h-[10px] text-2xl"
            placeholder={'Project title'}
            onSave={setTitle}
          />
        </div>
        <div className="mb-[10px] flex">
          <SingleLineEditorComponent
            className="h-auto"
            id={`${generatedId}-short-summary`}
            placeholder={'Short summary'}
            onSave={setShortSummary}
          />
        </div>
      </div>

      <div className="px-[10px]">
        <ProjectPropertiesComponent
          disabled={false}
          selectedStartAt={selectedStartAt}
          selectedEndAt={selectedEndAt}
          onSelectedStartAt={setSelectedStartAt}
          onSelectedEndAt={setSelectedEndAt}
          teamIds={selectedTeamId ? [selectedTeamId] : []}
          selectedProjectStatusId={selectedProjectStatusId}
          onSelectedProjectStatusId={setSelectedProjectStatusId}
          selectedPriority={selectedPriority}
          onSelectedPriority={setSelectedPriority}
          selectedLabelIds={selectedLabelIds}
          onSelectedLabelIds={setSelectedLabelIds}
          selectedLeadId={selectedLeadId}
          onSelectedLeadId={onSelectedLeadId}
          selectedMemberIds={selectedMemberIds}
          onSelectedMemberIds={setSelectedMemberIds}
          onCopyId={() => {}}
          onCopyLink={() => {}}
        />
      </div>

      <div
        className={classNames(
          'flex min-h-[200px] w-full grow flex-col overflow-auto px-[10px]',
        )}
      >
        <EditorPlateComponent
          placeholder="Add description ..."
          id={`${generatedId}-description`}
          type={'project'}
          workspace_id={selectedWorkspace?.id ?? ''}
          onSave={setDescription}
          className="min-h-[200px]"
        />
      </div>
      <div className="flex-none">
        <div className="w-full flex-none">
          <Separator className="my-[10px]  w-full flex-none" />
          <div className="flex flex-none flex-row justify-end gap-2 p-[10px] pt-0">
            <Button
              type="button"
              size={'sm'}
              variant={'secondary'}
              onClick={() => onSubmitCreate()}
              disabled={createProject.isPending}
            >
              <Label>Create project</Label>
            </Button>
          </div>
        </div>
      </div>
    </>
  );
};
