import { useState } from 'react';

import { useMutation } from '@apollo/client';

import { useErrorHandler } from '@/hooks/useToasterHandler';
import { CreateAttachmentDocument } from '@/types/apollo-client/file/graphql/mutations.graphql.generated';

interface UseUploadFileProps {
  onUploadComplete?: (file: UploadedFile) => void;
  onUploadError?: (error: unknown) => void;
  headers?: Record<string, string>;
  onUploadBegin?: (fileName: string) => void;
  onUploadProgress?: (progress: { progress: number }) => void;
  skipPolling?: boolean;
  id: string;
  type: string;
  workspace_id: string;
}

interface UploadedFile {
  key: string; // Unique identifier
  url: string; // Public URL of the uploaded file
  name: string; // Original filename
  size: number; // File size in bytes
  type: string; // MIME type
}
export function useUploadFile(props: UseUploadFileProps) {
  const [uploadedFile, setUploadedFile] = useState<UploadedFile>();
  const [uploadingFile, setUploadingFile] = useState<File>();
  const [progress, setProgress] = useState(0);
  const [isUploading, setIsUploading] = useState(false);

  const { showErrorToast } = useErrorHandler();

  const [createAttachment, { loading }] = useMutation(CreateAttachmentDocument);

  async function uploadFile(file: File) {
    setIsUploading(true);
    setUploadingFile(file);

    try {
      const interval = setInterval(() => {
        const getProgress = Math.min(100, Math.floor(Math.random() * 100));
        setProgress(getProgress);
        props.onUploadProgress?.({ progress: getProgress });
        if (getProgress === 100) clearInterval(interval);
      }, 100);
      // eslint-disable-next-line no-promise-executor-return
      await new Promise((resolve) => setTimeout(resolve, 1000));
      const res = await createAttachment({
        variables: {
          createInput: {
            type: props.type,
            file: file,
            workspace_id: props.workspace_id,
          },
        },
      });

      const completedUploadedFile = {
        key: res.data?.createAttachment?.id,
        url: res.data?.createAttachment?.path,
        name: file.name,
        size: file.size,
        type: file.type,
      };

      setUploadedFile(completedUploadedFile);
      props.onUploadComplete?.(completedUploadedFile);

      return uploadedFile;
    } catch (error) {
      props.onUploadError?.(error);
      throw error;
    } finally {
      setProgress(0);
      setIsUploading(false);
      setUploadingFile(undefined);
    }
  }

  return {
    isUploading,
    progress,
    uploadFile,
    uploadedFile,
    uploadingFile,
  };
}
