import { createContext } from 'react';

type TDocumentContentHistoryContext = {
  isOpen: boolean;
  documentId: string | undefined;
  showModal: (props: {
    isOpen: boolean;
    documentId?: string | undefined;
  }) => void;
};

const DocumentContentHistoryContext =
  createContext<TDocumentContentHistoryContext>({
    isOpen: false,
    documentId: undefined,
    showModal: () => undefined,
  });

export default DocumentContentHistoryContext;
